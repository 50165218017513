// React
import React from "react";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// A ---------------------------------------------------------------------- M

const SecretAction = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const actionForm = (
    <>
      <TextField
        margin="dense"
        id={`Mnemonic-${props.label}`}
        label="24-words secret"
        name={`Mnemonic-${props.label}`}
        disabled={props.label === "Generate"}
        required
        fullWidth
        rows={5}
        multiline
        value={props.mnemonic}
        onChange={(e) => props.setMnemonic(e.target.value)}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          fullWidth
          variant="contained"
          disabled={!props.mnemonic}
          onClick={props.handleAction}
        >
          {props.action}
        </Button>
      </Box>
    </>
  );

  const actionBox = (
    <Box sx={{ p: isMobile ? "5%" : "2%" }}>
      <Grid
        container
        alignItems="center"
        justifyContent={isMobile ? "center" : "space-between"}
        mt={isMobile ? "5%" : "0%"}
        mb={isMobile ? "5%" : "1%"}
      >
        <Grid item>
          <Typography variant="h6">{props.action}</Typography>
        </Grid>
        {!isMobile && (
          <Grid item>
            <IconButton
              onClick={props.handleClose}
              edge="end"
              sx={{ color: "red" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {actionForm}
    </Box>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={props.open}
      onClose={props.handleClose}
      onOpen={() => props.setOpen(true)}
      sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      {props.loading ? <LoadingComponent /> : actionBox}
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth="md"
    >
      {props.loading ? <LoadingComponent /> : actionBox}
    </Dialog>
  );
};

export default SecretAction;
