// React
import React, { useState, useEffect } from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Material UI
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import { CertificationSuccessful, CertificationError } from 'ui-components/ORFeedbacks';
import CertificationsTable from 'ui-components/CertificationsTable';

// Generators
import DocGenerator from 'ui-components/generators/DocGenerator';
import ImageGenerator from 'ui-components/generators/ImageGenerator';
import InfoGenerator from 'ui-components/generators/InfoGenerator';
import CDNFileGenerator from 'ui-components/generators/CDNFileGenerator/CDNFileGenerator';

// Icons
import DatapointIntegrityInspector from 'ui-components/DatapointIntegrityInspector';

// Firebase
import { db } from 'config/firebase';
import { doc, collection, onSnapshot, query, where } from 'firebase/firestore';

// Functions
import fetchSeenTags from 'user/fetchSeenTags';
import fetchSignatures from 'user/fetchSignatures';
import verifySignature from 'verificator/verifySignature';

// A ---------------------------------------------------------------------- M

const MyCertifications = () => {
    const { user, seedflow, canSign, conservSostL1, APIKey } = UserAuth();

    const [seentags, setSeenTags] = useState([]);
    const [signatures, setSignatures] = useState([]);
    const [record, setRecord] = useState();
    const [verification, setVerification] = useState();
    const [openDocGen, setOpenDocGen] = useState(false);
    const [openImageGen, setOpenImageGen] = useState(false);
    const [openInfoGen, setOpenInfoGen] = useState(false);
    const [openUploadGen, setOpenUploadGen] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openCertificationSuccessful, setOpenCertificationSuccessful] = useState(false);
    const [openCertificationError, setOpenCertificationError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getSeenTags = async () => {
            if (user) {
                const seentags = await fetchSeenTags(user.uid);
                seentags.sort((tag1, tag2) => tag2.firstseen - tag1.firstseen);
                setSeenTags(seentags);
            }
        };

        const getSignatures = async () => {
            if (user) {
                const signatures = await fetchSignatures(user.uid);
                signatures.sort((sig1, sig2) => sig2.timestamp - sig1.timestamp);
                setSignatures(signatures);
            }
        };

        const fetchData = async () => {
            await getSeenTags();
            await getSignatures();
            setIsLoading(false);
        };

        fetchData();

        const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
        const userdataDocRef = doc(db, 'userdata', user.uid);
        const mysigsCollectionRef = collection(userdataDocRef, 'mysigs');

        const newAdditionsQueryCertifications = query(
            mysigsCollectionRef,
            where('timestamp', '>', currentTimestampInSeconds.toString())
        );

        const newAdditionsUnsubscribeCertifications = onSnapshot(newAdditionsQueryCertifications, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    getSignatures();
                }
            });
        });

        return () => {
            newAdditionsUnsubscribeCertifications();
        };
    }, [user]);

    const openDocGenDrawer = () => {
        setOpenDocGen(true);
        window.history.pushState(null, '');
    };

    const openImageGenDrawer = () => {
        setOpenImageGen(true);
        window.history.pushState(null, '');
    };

    const openInfoGenDrawer = () => {
        setOpenInfoGen(true);
        window.history.pushState(null, '');
    };

    const openUploadGenDrawer = () => {
        setOpenUploadGen(true);
        window.history.pushState(null, '');
    };

    const openViewDrawer = () => {
        setOpenView(true);
        window.history.pushState(null, '');
    };

    const handleOpenCertificationSuccessful = () => {
        setOpenCertificationSuccessful(true);
        window.history.pushState(null, '');
    };

    const handleOpenCertificationError = () => {
        setOpenCertificationError(true);
        window.history.pushState(null, '');
    };

    const clickView = async (record) => {
        const verification = await verifySignature(user.uid, record, record.type);
        setRecord(record);
        setVerification(verification);
        openViewDrawer();
    };

    return (
        <>
            {isLoading ?
                <Grid item container justifyContent='center' mt='30%'>
                    <CircularProgress />
                </Grid> :
                <>
                    <Grid item xs={12}>
                        <CertificationsTable
                            signatures={signatures}
                            clickView={clickView}
                            openDocGenDrawer={openDocGenDrawer}
                            openImageGenDrawer={openImageGenDrawer}
                            openInfoGenDrawer={openInfoGenDrawer}
                            openUploadGenDrawer={openUploadGenDrawer}
                        />
                    </Grid>

                    {canSign && seedflow &&
                        <>
                            <DocGenerator
                                tags={seentags}
                                open={openDocGen}
                                setOpen={setOpenDocGen}
                                handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
                                handleOpenCertificationError={handleOpenCertificationError}
                            />

                            <ImageGenerator
                                tags={seentags}
                                open={openImageGen}
                                setOpen={setOpenImageGen}
                                handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
                                handleOpenCertificationError={handleOpenCertificationError}
                            />

                            <InfoGenerator
                                tags={seentags}
                                open={openInfoGen}
                                setOpen={setOpenInfoGen}
                                handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
                                handleOpenCertificationError={handleOpenCertificationError}
                            />
                        </>
                    }

                    {conservSostL1 && APIKey &&
                        <CDNFileGenerator
                            tags={seentags}
                            open={openUploadGen}
                            setOpen={setOpenUploadGen}
                            handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
                            handleOpenCertificationError={handleOpenCertificationError}
                        />
                    }

                    {record && verification &&
                        <DatapointIntegrityInspector
                            record={record}
                            verification={verification}
                            open={openView}
                            setOpen={setOpenView}
                        />
                    }

                    <CertificationSuccessful
                        open={openCertificationSuccessful}
                        setOpen={setOpenCertificationSuccessful}
                    />

                    <CertificationError
                        open={openCertificationError}
                        setOpen={setOpenCertificationError}
                    />
                </>
            }
        </>
    );
};

export default MyCertifications;