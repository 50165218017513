// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

// Functions
import getDataKeyData from "datakey/getDataKeyData";
import getCertificationsByCreator from "tag/getCertificationsByCreator";

// A ---------------------------------------------------------------------- M

const getAuthorizedKeysData = async (databoxID) => {
    try {
        // Reference to the databox document
        const databoxRef = doc(db, 'tagsdata', databoxID);

        // Reference to the 'authorized_keys' collection
        const authorizedKeysRef = collection(databoxRef, 'authorized_keys');

        // Getting the documents
        const snapshot = await getDocs(authorizedKeysRef);

        // Initialize total certifications counter
        let totalNumberOfCertificationsKeys = 0;

        const authorizedKeysPromises = snapshot.docs.map(async (authKey) => {
            // Key Data
            const keyData = await getDataKeyData(authKey.id);

            let keyName;
            let keyOwner;

            if (keyData) {
                // Key Name
                keyName = keyData.name;

                // Key Owner
                keyOwner = keyData.owner_uuid;
            }

            // Key Added On
            const keyAddedOn = authKey.data().added_on;

            // Get key certifications for this databox
            const keyCertifications = await getCertificationsByCreator(databoxID, authKey.id);

            // Accumulate the total number of certifications
            totalNumberOfCertificationsKeys += keyCertifications.number_of_certifications;

            return {
                id: authKey.id,
                name: keyName,
                added_on: keyAddedOn,
                owner_uuid: keyOwner,
                key_certifications: keyCertifications.certifications ? keyCertifications.certifications : [],
                key_number_of_certifications: keyCertifications.number_of_certifications ? keyCertifications.number_of_certifications : 0,
            };
        });

        const authorizedKeysArray = await Promise.all(authorizedKeysPromises);

        console.log('authorizedKeysArray:', authorizedKeysArray);
        console.log('TtotalNumberOfCertificationsKeys:', totalNumberOfCertificationsKeys);

        return {
            authorizedKeysArray,
            totalNumberOfCertificationsKeys,
        }
    } catch (e) {
        console.error("Error in getAuthorizedKeysData:", e.message);
        return {
            authorizedKeysArray: [],
            totalNumberOfCertificationsKeys: 0,
        }
    }
};

export default getAuthorizedKeysData;