// React
import React, { useState, useRef } from 'react';

// react-to-pdf
import generatePDF from 'react-to-pdf';

// Material UI
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// A ---------------------------------------------------------------------- M

const CDNFileUploadReceipt = ({ receiptDialogOpen, handleResetUploadReceipt, uploadReceipt }) => {
    const targetRef = useRef();

    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

    const handleDownloadPDF = async () => {
        setIsGeneratingPDF(true);
        await generatePDF(targetRef, { filename: 'receipt.pdf' });
        setIsGeneratingPDF(false);
    };

    return (
        <Dialog open={receiptDialogOpen} onClose={handleResetUploadReceipt} fullWidth maxWidth='lg'>
            <Box sx={{ p: '2%' }}>
                <Grid container alignItems='center' justifyContent='space-between' mb='3%'>
                    <Grid item>
                        <Typography variant='h5' fontWeight='bold'>Conferma di upload</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleResetUploadReceipt} sx={{ color: 'red' }} edge='end'>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item container spacing={3} ref={targetRef}>
                        <Grid item xs={12}>
                            <Paper elevation={4} sx={{ p: '2%' }}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={0.5}>
                                        <CheckCircleIcon sx={{ color: 'green' }} fontSize='large' />
                                    </Grid>
                                    <Grid item xs={11.5}><Typography variant='h6'>Il documento è stato caricato e conservato correttamente.</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={10} sx={{ p: '2%' }}>
                                <Typography>Al documento è stato assegnato il seguente ID: <br /><b>{uploadReceipt.txid}</b></Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='flex-end'>
                        <Button variant='contained' onClick={handleDownloadPDF} disabled={isGeneratingPDF}>
                            {isGeneratingPDF ? 'Generating PDF...' : 'Download as PDF'}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default CDNFileUploadReceipt;