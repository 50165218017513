// React
import React from "react";
import { useNavigate } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

// Icons
import FolderIcon from "@mui/icons-material/Folder";
import PeopleIcon from "@mui/icons-material/People";

// A ---------------------------------------------------------------------- M

const GroupToolbar = ({ group }) => {
  const { conservSostL1 } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          elevation={4}
          sx={{
            p: "2%",
            backgroundColor: "#f5f5f5",
            borderRadius: 8,
          }}
        >
          <Grid container spacing={3}>
            <Grid item container xs={12}>
              <Grid item xs={8}>
                <Typography variant="h4" fontWeight="bold">
                  {group.name || "N/A"}
                </Typography>
              </Grid>
              <Grid item container xs={4} justifyContent="flex-end">
                <Typography
                  sx={{
                    backgroundColor: "gray",
                    color: "#ffffff",
                    borderRadius: 1,
                    px: 2,
                    py: 1,
                    fontWeight: "bold",
                    fontSize: "1rem",
                  }}
                >
                  {t("group")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography gutterBottom color="gray" variant="h6">
                  {t("group_information")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <b>{t("id")}</b>: {group.id}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <b>{t("name")}</b>: {group.name || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  <b>{t("creator_email")}</b>: {group.creator_email || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
        <Grid item xs={12} sm={12} md={2} lg={1.2}>
          <Button
            startIcon={<PeopleIcon />}
            variant="contained"
            fullWidth
            onClick={() => navigate(`/${group.id}/members`)}
          >
            {t("members")}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={1.2}>
          <Button
            startIcon={<FolderIcon />}
            variant="contained"
            fullWidth
            onClick={() => navigate(`/${group.id}/databoxes`)}
          >
            {conservSostL1 ? t("archives") : t("databoxes")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupToolbar;
