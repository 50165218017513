// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

// Functions
import getUserContacts from "user/getUserContacts";
import getCertificationsByCreator from "tag/getCertificationsByCreator";

// A ---------------------------------------------------------------------- M

const getAuthorizedUsersData = async (databoxID) => {
    try {
        // Reference to the databox document
        const databoxRef = doc(db, 'tagpermissions', databoxID);

        // Reference to the 'authorized_users' collection
        const authorizedUsersRef = collection(databoxRef, 'authorized_users');

        // Getting the documents
        const snapshot = await getDocs(authorizedUsersRef);

        // Initialize total certifications counter
        let totalNumberOfCertifications = 0;

        const authorizedUsersPromises = snapshot.docs.map(async (authUser) => {
            // Get user contacts
            const userContacts = await getUserContacts(authUser.id);

            // Get user certifications for this databox
            const userCertifications = await getCertificationsByCreator(databoxID, authUser.id);

            // Accumulate the total number of certifications
            totalNumberOfCertifications += userCertifications.number_of_certifications;

            return {
                user_email: userContacts.email ? userContacts.email : "N/A",
                user_public_key: userContacts.public_key ? userContacts.public_key : "N/A",
                user_company: userContacts.company ? userContacts.company : "N/A",
                user_certifications: userCertifications.certifications ? userCertifications.certifications : [],
                user_number_of_certifications: userCertifications.number_of_certifications ? userCertifications.number_of_certifications : 0,
            };
        });

        const authorizedUsersArray = await Promise.all(authorizedUsersPromises);

        console.log('authorizedUsersArray:', authorizedUsersArray);
        console.log('totalNumberOfCertifications:', totalNumberOfCertifications)

        return {
            authorizedUsersArray,
            totalNumberOfCertifications,
        }
    } catch (e) {
        console.error("Error in getAuthorizedUsersData:", e.message);
        return {
            authorizedUsersArray: [],
            totalNumberOfCertifications: 0,
        }
    }
};

export default getAuthorizedUsersData;