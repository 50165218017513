// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

// Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

// Components
import { LoadingComponent } from "./LoadingComponent";
import { Puller } from "./Puller";

// Functions
import addSeenTag from "user/addSeenTag";

// A ---------------------------------------------------------------------- M

export const LoginSuccessful = ({ open, handleCloseSuccess }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (open) {
      setProgress(0);
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + 3.33;
          if (nextProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          return nextProgress;
        });
      }, 100);

      return () => {
        clearInterval(timer);
      };
    }
  }, [open]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleCloseSuccess}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: isMobile ? "0%" : "2.5%" }}
    >
      <Alert variant="filled" severity="success">
        <Typography align="center">{t("login_successful")}</Typography>
      </Alert>
    </Snackbar>
  );
};

export const ResetPasswordDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText fontSize="20px">
          {t("reset_password_text")}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {t("go_to_sigin")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DataBoxFound = ({ tag, open, setOpen }) => {
  const { user, conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setOpen(false);
    navigate("/");
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await addSeenTag(tag.id, user.uid, tag.name, description);
    } catch (error) {
      console.log("Error in handleSave:", error.message);
    } finally {
      setLoading(false);
      setOpen(false);
      window.location.reload();
    }
  };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth>
      <DialogTitle>
        {!conservSostL1 ? "Save Databox" : "Save Archive"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {!conservSostL1 ? t("databox") : t("archive")}: <b>{tag.name}</b>{" "}
          {t("databox_found_text_part")}
        </Typography>
        <TextField
          margin="dense"
          id="Description"
          label={t("description")}
          name="Description"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={2}
          multiline
        />
      </DialogContent>
      {loading && <LoadingComponent />}
      <DialogActions>
        <Button fullWidth variant="contained" onClick={handleCancel}>
          {t("cancel")}
        </Button>
        <Button fullWidth variant="contained" onClick={handleSave}>
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CertificationSuccessful = ({ open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {!conservSostL1 ? "Certification" : "Upload"} Successful
      </Alert>
    </Snackbar>
  );
};

export const CertificationError = ({ open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {!conservSostL1 ? "Certification" : "Upload"} Failed
      </Alert>
    </Snackbar>
  );
};

export const DataboxCreationSuccessfulSnackbar = ({ open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {!conservSostL1 ? "Databox" : "Archive"} Created Successfully
      </Alert>
    </Snackbar>
  );
};

export const GroupCreationSuccessfulSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        Group Created Successfully
      </Alert>
    </Snackbar>
  );
};

export const ImportKeyDialog = (props) => {
  return (
    <Dialog
      open={props.openMnemonicForm}
      onClose={props.handleCloseMnemonicForm}
    >
      <DialogTitle>BIP39 24-words mnemonic phrase</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mt: "1%" }}>
          To import a new secret, please type or paste your BIP39 24-words
          mnemonic phrase.
        </DialogContentText>
        {props.loading ? <LoadingComponent /> : props.BIP39Input}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={props.loading}
          onClick={props.handleCloseMnemonicForm}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={props.loading || !props.mnemonic}
          onClick={props.handleLoadMnemonic}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ImportedKeySnackbar = (props) => {
  return (
    <Snackbar
      open={props.loaded}
      autoHideDuration={5000}
      onClose={props.handleCloseLoaded}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ mt: { xs: "20%", md: "5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        Secret Imported Successfully
      </Alert>
    </Snackbar>
  );
};

export const ImportKeyDrawer = (props) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.openMnemonicForm}
      onClose={props.handleCloseMnemonicForm}
      onOpen={props.handleOpenMnemonicForm}
      sx={{
        width: "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": { width: "100%" },
      }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      <Typography variant="h5" align="center" sx={{ mt: "10%" }}>
        Import Secret
      </Typography>
      <Box sx={{ p: "6%" }}>
        <Typography variant="body1">
          To import a new secret, please type or paste your BIP39 24-words
          mnemonic phrase.
        </Typography>
        {props.loading ? <LoadingComponent /> : props.BIP39Input}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
          <Button
            variant="contained"
            disabled={props.loading}
            onClick={props.handleCloseMnemonicForm}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={props.loading || !props.mnemonic}
            onClick={props.handleLoadMnemonic}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export const ImportedKeyDrawer = (props) => {
  return (
    <Drawer
      anchor="bottom"
      open={props.loaded}
      sx={{
        width: "100%",
        flexShrink: 0,
        "& .MuiDrawer-paper": { width: "100%" },
      }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Box sx={{ p: "6%", mt: "5%" }}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <CheckCircleOutlineIcon fontSize="large" sx={{ color: "green" }} />
        </Box>
        <Typography variant="h5" color="green" align="center" gutterBottom>
          Secret Imported Successfully
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 3 }}>
          <Button variant="contained" onClick={props.handleCloseLoaded}>
            OK
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export const NewCertConfirm = (props) => {
  const { conservSostL1 } = UserAuth();

  return (
    <Dialog
      open={props.confirm}
      onClose={() => props.setConfirm(false)}
      fullWidth
    >
      <DialogTitle>
        New {!conservSostL1 ? "Certification" : "Upload"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Are you {!conservSostL1 ? "creating" : "uploading"} a new{" "}
          {!conservSostL1 ? "certification" : "document"}. Do you confirm?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.setConfirm(false)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={props.handleGenerate}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NewRequestReject = (props) => {
  const { conservSostL1 } = UserAuth();

  return (
    <Dialog open={props.reject} onClose={() => props.setReject(false)}>
      <DialogTitle>
        Reject {!conservSostL1 ? "Certification" : "Upload"} Request
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Are you rejecting a {!conservSostL1 ? "certification" : "upload"}{" "}
          request. Do you confirm?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.setReject(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={props.handleReject}
          sx={{ bgcolor: "red", color: "white" }}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NewRequestConfirm = (props) => {
  const { conservSostL1 } = UserAuth();

  return (
    <Dialog open={props.approval} onClose={() => props.setApproval(false)}>
      <DialogTitle>
        New {!conservSostL1 ? "Certification" : "Upload"} Request
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Are you sending a new {!conservSostL1 ? "certification" : "upload"}{" "}
          request to the {!conservSostL1 ? "databox" : "archives"}'s owner. Do
          you confirm?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.setApproval(false)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={props.handleSendApprovalRequest}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MemberAddedSuccessfulSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        Member Added Successfully
      </Alert>
    </Snackbar>
  );
};

export const MemberAddedErrorSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        Error in Adding Member
      </Alert>
    </Snackbar>
  );
};

export const MemberRemoveConfirm = (props) => {
  return (
    <Dialog
      open={props.showRemove}
      onClose={() => props.setShowRemove(false)}
      fullWidth
    >
      <DialogTitle>Remove Member</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Are you sure to remove this member?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setShowRemove(false)} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={props.handleRemoveMember}
          variant="contained"
          sx={{ bgcolor: "red", color: "white" }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MemberRemovedSuccessfulSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        Member Removed Successfully
      </Alert>
    </Snackbar>
  );
};

export const MemberRemovedErrorSnackbar = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        Error in Removing Member
      </Alert>
    </Snackbar>
  );
};

export const GroupRemoveConfirm = (props) => {
  return (
    <Dialog open={props.showRemove} onClose={() => props.setShowRemove(false)}>
      <DialogTitle>Remove group</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Are you sure to remove this group?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setShowRemove(false)} variant="contained">
          Cancel
        </Button>
        <Button
          onClick={props.handleRemoveGroup}
          variant="contained"
          sx={{ bgcolor: "red", color: "white" }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const EnabledPublicKeySuccessful = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        Public Key Activated Successfully
      </Alert>
    </Snackbar>
  );
};

export const EnabledPublicKeyError = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        Error in Activating Public Key
      </Alert>
    </Snackbar>
  );
};

export const EnabledAPIKeySuccessful = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        API Key Activated Successfully
      </Alert>
    </Snackbar>
  );
};

export const EnabledAPIKeyError = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        Error in Activating API Key
      </Alert>
    </Snackbar>
  );
};

export const NewDataKeySuccessful = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        Data Key Created Successfully
      </Alert>
    </Snackbar>
  );
};

export const NewDataKeyError = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        Error in Creating Data Key
      </Alert>
    </Snackbar>
  );
};

export const InvalidFile = ({ open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Snackbar
      open={open}
      autoHideDuration={8000}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: "center",
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      sx={{ mt: { md: "2.5%" } }}
    >
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        Invalid file. Please select a valid file.
      </Alert>
    </Snackbar>
  );
};
