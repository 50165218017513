// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// Functions
import addSignature from "user/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamCDNFile = async (uid, dataBody, dataCertification, timestamp, tdr) => {
    try {
        // Record transaction id (txid)
        const txid = dataCertification.txid;

        // Create new record in recordsdata collection, using txid as document id
        const recordRef = doc(db, "recordsdata", txid);

        // Set the new record
        const recordData = {
            txid: txid,
            type: "CDNFile",
            version: 1,
            tdr: tdr,
            creator_uuid: uid,
            data: {
                attachment: dataBody.downloadURL,
                timestamp: timestamp,
                fileName: dataBody.fileName,
                fileType: dataBody.fileType,
            },
            timestamp: timestamp,
            fileName: dataBody.fileName,
            fileType: dataBody.fileType,
            ...dataBody.documentProperties,
        };

        console.log(recordData);

        await setDoc(recordRef, recordData);

        // Create record link to tagsdata collection, using txid as foreign key.
        const tagRef = doc(db, "tagsdata", tdr);

        // Signatures collection reference related to the tag
        const recordsRef = collection(tagRef, "signatures");

        // Set new record link to the signatures collection
        const signatureData = {
            txid: txid,
            type: "CDNFile",
            creator_uuid: uid,
            db_id: 1,
            timestamp: timestamp,
            fileName: dataBody.fileName,
            fileType: dataBody.fileType,
            ...dataBody.documentProperties,
        };

        console.log(signatureData);

        await setDoc(doc(recordsRef, txid), signatureData);

        // Add signature to mysigs subcollection of userdata collection
        await addSignature(uid, txid, timestamp, "CDNFile", tdr);

        // Update the uploadStatuses collection with the document id uploadID
        const uploadStatusRef = doc(db, "uploadStatuses", dataBody.uploadID);

        // Set the txid attribute in the uploadStatuses document
        const uploadStatusData = {
            txid: txid,
        };

        console.log(uploadStatusData);

        await setDoc(uploadStatusRef, uploadStatusData, { merge: true });

        const result = {
            txid: txid,
            success: true,
        }

        console.log(result);

        return result;
    } catch (e) {
        console.error("Error in sendDataStreamCDNFile:", e.message);

        const result = {
            success: false,
            error: e.message
        };

        return result;
    }
};

export default sendDataStreamCDNFile;