// React
import React, { useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Icons
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import InfoIcon from '@mui/icons-material/Info';

// A ---------------------------------------------------------------------- M

const useStyles = makeStyles(() => ({
    input: {
        display: "none"
    }
}));

const CDNFileForm = ({
    isMobile,
    request,
    tags,
    location,
    source,
    downloadURL,
    blobURL,
    fileName,
    documentProperties,
    tagSelectionOpen,
    selectedTag,
    handleDocumentPropertyChange,
    handleFileChange,
    handleChange,
    handleOpen,
    handleClose,
    handleFileClick
}) => {
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleClickOpen = (content) => {
        setDialogContent(content);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDialogContent('');
    };

    const metadataNotes = {
        nomeFile: {
            id: "nomeFile",
            name: "Nome File",
            description: "Nome alfanumerico del file così come riconosciuto all'esterno.",
            required: true,
        },
        formatoFile: {
            id: "formatoFile",
            name: "Formato File",
            description: "Es. pdf, es. jpeg, ecc.",
            required: true,
        },
        nomeFileDestinazione: {
            id: "nomeFileDestinazione",
            name: "Nome File Di Destinazione",
            description: "Nome con cui il file verrà conservato nel sistema di gestione documentale o archivio elettronico.",
            required: true,
        },
        cartellaDestinazione: {
            id: "cartellaDestinazione",
            name: "Cartella Di Destinazione",
            description: "Nome della cartella in cui il file verrà conservato nel sistema di gestione documentale o archivio elettronico.",
            required: true,
        },
        classeDocumentale: {
            id: "classeDocumentale",
            name: "Classe Documentale",
            description: "Es. fatture, contratti, libro giornale, registro, ecc.",
            required: true,
        },
        dataDocumento: {
            id: "dataDocumento",
            name: "Data Documento",
            description: "Data di emissione del documento in formato mm/dd/yyyy.",
            required: true,
        },
        impronta: {
            id: "impronta",
            name: "Impronta",
            description: "Hash del documento calcolato dal produttore del PdV.",
            required: true,
        },
        algoritmo: {
            id: "algoritmo",
            name: "Algoritmo",
            description: "Algoritmo applicato, default: SHA-256.",
            required: true,
        },
        identificativoDocumento: {
            id: "identificativoDocumento",
            name: "Identificativo Documento",
            description: "Es. documentID o identificativo SdI per le fatture o ID SAP o ID del sistema di gestione documentale o anche nome file.",
            required: true,
        },
        versioneDocumento: {
            id: "versioneDocumento",
            name: "Versione Documento",
            description: "1, 2, 3, ecc.",
            required: true,
        },
        modalitaFormazione: {
            id: "modalitaFormazione",
            name: "Modalità Di Formazione",
            description: <>
                <p>Valori ammessi:</p>
                <ul>
                    <li>a. creazione tramite l'utilizzo di strumenti software;</li>
                    <li>b. acquisizione per via telematica o della copia per immagine;</li>
                    <li>c. transazioni o processi informatici o moduli o formulari resi disponibili all'utente;</li>
                    <li>d. generazione da registrazioni o banca dati;</li>
                </ul>
                <p>Si può indicare proprio solo la lettera. Es. 'a' per fattura elettronica o 'b' per fattura cartacea scansionata, ecc.</p>
            </>,
            required: true,
        },
        tipologiaFlusso: {
            id: "tipologiaFlusso",
            name: "Tipologia Di Flusso",
            description: "In uscita o in entrata (per le fatture rispetto al ciclo attivo o passivo), interno (per i libri contabili). Si può indicare proprio solo la lettera U, E, I.",
            required: true,
        },
        tipoRegistro: {
            id: "tipoRegistro",
            name: "Tipo Registro",
            description: "Valori ammessi: Nessuno, Protocollo Ordinario/Protocollo Emergenza, Repertorio/Registro.",
            required: true,
        },
        dataRegistrazione: {
            id: "dataRegistrazione",
            name: "Data Registrazione",
            description: "Data/ora di registrazione del documento in formato mm/dd/yyyy.",
            required: true,
        },
        numeroDocumento: {
            id: "numeroDocumento",
            name: "Numero Documento",
            description: "Numero di registrazione del documento, es. numero di protocollo, ID SDI, numero documento fornitore, numero concatenato mese/anno.",
            required: true,
        },
        codiceRegistro: {
            id: "codiceRegistro",
            name: "Codice Registro",
            description: "Codice identificativo del registro in cui il documento viene registrato, nel caso in cui il tipo registro sia Protocollo Ordinario/Protocollo Emergenza, o Repertorio/Registro.",
            required: false,
        },
        oggetto: {
            id: "oggetto",
            name: "Oggetto",
            description: "Testo libero, es. fattura attiva.",
            required: true,
        },
        ruolo: {
            id: "ruolo",
            name: "Ruolo",
            description: "Valori ammessi: assegnatario, autore, mittente, destinatario, operatore, produttore, RGD (Responsabile della Gestione Documentale), RSP (Responsabile del Servizio di Protocollo), Soggetto che effettua la registrazione, altro. Almeno un soggetto che effettua la registrazione del documento (tipicamente l'Organizzazione che protocolla) e un autore o un mittente va indicato obbligatoriamente.",
            required: true,
        },
        tipoSoggetto: {
            id: "tipoSoggetto",
            name: "Tipo Soggetto",
            description: "Valori ammessi: PF per persona fisica, PG per organizzazione, PAI per amministrazione pubblica italiana, PAE per le Amministrazioni Pubbliche estere, SW per i documenti prodotti automaticamente (Se Ruolo = Produttore).",
            required: true,
        },
        cognome: {
            id: "cognome",
            name: "Cognome",
            description: "Obbligatorio se Tipo soggetto: PF.",
            required: false,
        },
        nome: {
            id: "nome",
            name: "Nome",
            description: "Obbligatorio se Tipo soggetto: PF.",
            required: false,
        },
        denominazione: {
            id: "denominazione",
            name: "Denominazione",
            description: "Obbligatorio se Tipo soggetto: PG, PAI, PAE, AS, SW. Per le PA può essere ricorsivo e riferito al Codice IPA dell'Amministrazione, dell'AOO, dell'UOR, o all' Ufficio.",
            required: false,
        },
        codiceFiscale: {
            id: "codiceFiscale",
            name: "Codice Fiscale",
            description: "O Partita Iva.",
            required: false,
        },
        indirizziDigitaliDiRiferimento: {
            id: "indirizziDigitaliDiRiferimento",
            name: "Indrizzi Digitali Di Riferimento",
            description: "Se presenti.",
            required: false,
        },
        allegatiNumero: {
            id: "allegatiNumero",
            name: "Allegati Numero",
            description: "Valori ammessi: 0, 1, 2, ... Es. allegato a un contratto, considerato come un secondo file, versato separatamente, con un suo set di metadati e collegato al 'contratto padre' mediante questo metadato. Non rientrano gli allegati 'embedded' nei documenti.",
            required: true,
        },
        IdDocIndiceAllegati: {
            id: "IdDocIndiceAllegati",
            name: "IdDoc Indice Allegati",
            description: "Da indicare per ogni allegato se Numero allegati > 0.",
            required: false,
        },
        descrizioneAllegati: {
            id: "descrizioneAllegati",
            name: "Descrizione Allegati",
            description: "Testo libero",
            required: false,
        },
        indiceDiClassificazione: {
            id: "indiceDiClassificazione",
            name: "Indice Di Classificazione",
            description: "Codifica del documento secondo il Piano di classificazione utilizzato (obbligatorio per le PA).",
            required: false,
        },
        descrizioneClassificazione: {
            id: "descrizioneClassificazione",
            name: "Descrizione Classificazione",
            description: "Descrizione per esteso dell'Indice di classificazione indicato (obbligatorio per le PA).",
            required: false,
        },
        riservato: {
            id: "riservato",
            name: "Riservato",
            description: "Boolean true/false in relazione alla visibilità nei Sistemi di Gestione Documentale di formazione e gestione.",
            required: true,
        },
        pianoClassificazione: {
            id: "pianoClassificazione",
            name: "Piano Classificazione",
            description: "URI del Piano di classificazione pubblicato.",
            required: true,
        },
        prodottoSoftwareNome: {
            id: "prodottoSoftwareNome",
            name: "Prodotto Software Nome",
            description: "Se disponibile e da validare soprattutto se si conservano formati proprietari vedi Allegato 2 Linee Guida.",
            required: false,
        },
        prodottoSoftwareVersione: {
            id: "prodottoSoftwareVersione",
            name: "Prodotto Software Versione",
            description: "Prodotto software versione.",
            required: false,
        },
        prodottoSoftwareProduttore: {
            id: "prodottoSoftwareProduttore",
            name: "Prodotto Software Produttore",
            description: "Prodotto software produttore.",
            required: false,
        },
        verificaFirmaDigitale: {
            id: "verificaFirmaDigitale",
            name: "Verifica Firma Digitale",
            description: "Verifica fatta dal produttore del PdV sulla presenza/assenza obbligatorio nel solo caso di modalità di formazione doc = a/b boolean true/false.",
            required: false,
        },
        verificaMarcaTemporale: {
            id: "verificaMarcaTemporale",
            name: "Verifica Marca Temporale",
            description: "Verifica marca temporale.",
            required: false,
        },
        verificaSigillo: {
            id: "verificaSigillo",
            name: "Verifica Sigillo",
            description: "Verifica sigillo.",
            required: false,
        },
        verificaConformitaCopie: {
            id: "verificaConformitaCopie",
            name: "Verifica Conformità Copie",
            description: "Verifica conformità copie.",
            required: false,
        },
        IdAggregazione: {
            id: "IdAggregazione",
            name: "Id Aggregazione",
            description: "Identificativo del fascicolo o della serie es. 2.3.1.2020 indica il primo fascicolo dell'anno 2020 nel titolo 2 classe 3 del titolario di classificazione.",
            required: false,
        },
        IdentificativoDocumentoPrincipale: {
            id: "IdentificativoDocumentoPrincipale",
            name: "Identificativo Documento Principale",
            description: "Identificativo univoco e persistente del documento principale, da popolare nel versamento di un documento allegato al documento principale, per creare un vincolo tra i due.",
            required: false,
        },
        tracciaturaModificheTipo: {
            id: "tracciaturaModificheTipo",
            name: "Tracciatura Modifiche - Tipo",
            description: "Nel caso di versione > 1 (rettifica) Valori ammessi: annullamento, rettifica, integrazione, annotazione metadato volto a tracciare la presenza di operazioni di modifica effettuate sul documento.",
            required: false,
        },
        soggettoAutoreModifica: {
            id: "soggettoAutoreModifica",
            name: "Soggetto Autore Della Modifica",
            description: "Nel caso di versione > 1 (rettifica) come da ruolo = Operatore definito nel metadato Soggetti.",
            required: false,
        },
        tracciaturaModificheData: {
            id: "tracciaturaModificheData",
            name: "Tracciatura Modifiche - Data",
            description: "Nel caso di versione > 1 (rettifica) data e ora della modifica.",
            required: false,
        },
        tracciaturaModificheIdDocVersionePrecedente: {
            id: "tracciaturaModificheIdDocVersionePrecedente",
            name: "Tracciatura Modifiche - IdDoc Versione Precedente",
            description: "Nel caso di versione > 1 (rettifica) identificativo versione precedente.",
            required: false,
        },
        tempoConservazione: {
            id: "tempoConservazione",
            name: "Tempo Di Conservazione",
            description: "Es. 10 anni (non comporta scarti automatici).",
            required: false,
        },
        note: {
            id: "note",
            name: "Note",
            description: "Note",
            required: false,
        },
    };

    return (
        <>
            <Grid container spacing={5}>
                {location.pathname === '/' && tags &&
                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel>Databox</InputLabel>
                            <Select
                                label="Databox"
                                name="Databox"
                                value={selectedTag}
                                onChange={handleChange}
                                open={tagSelectionOpen}
                                onOpen={handleOpen}
                                onClose={handleClose}
                            >
                                {tags.map((tag) => <MenuItem key={tag.id} value={tag.id}>{tag.nickname}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                }

                {/* Section: File Upload */}
                <Grid item container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color='gray'>Carica File</Typography>
                        <Divider />
                    </Grid>
                    {request ?
                        <Grid item container alignItems='center' spacing={1}>
                            <Grid item xs={12} sm={1}>
                                <Typography>File *</Typography>
                            </Grid>
                            <Grid item xs={12} sm={11}>
                                <Button
                                    startIcon={<FileOpenIcon />}
                                    onClick={() => handleFileClick(downloadURL)}
                                    variant='contained'
                                    sx={{ width: isMobile ? '100%' : '30%' }}
                                >
                                    {fileName || 'File selezionato'}
                                </Button>
                            </Grid>
                        </Grid> :
                        <Grid item container alignItems='center' spacing={1}>
                            <Grid item xs={12} sm={1}>
                                <Typography>File *</Typography>
                            </Grid>
                            <Grid item xs={12} sm={11}>
                                <input
                                    className={classes.input}
                                    id="new-upload-button"
                                    type="file"
                                    accept=".pdf, application/pdf, image/jpeg, image/jpg, image/png"
                                    disabled={request}
                                    onChange={(e) => handleFileChange(e.target)}
                                />
                                <label htmlFor="new-upload-button">
                                    <Button
                                        startIcon={<FileUploadIcon />}
                                        disabled={request}
                                        variant="contained"
                                        component="span"
                                        sx={{ width: isMobile ? '100%' : '30%' }}
                                    >
                                        Seleziona File
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <Typography>Nome File *</Typography>
                            </Grid>
                            {source ?
                                <Grid item xs={12} sm={11}>
                                    <Button
                                        startIcon={<FileOpenIcon />}
                                        onClick={() => handleFileClick(blobURL)}
                                        variant='contained'
                                        sx={{ width: isMobile ? '100%' : '30%' }}
                                    >
                                        {fileName || 'File selezionato'}
                                    </Button>
                                </Grid> :
                                <Grid item xs={12} sm={10.5}>
                                    <Typography>Nessun file selezionato</Typography>
                                </Grid>
                            }
                        </Grid>
                    }
                </Grid>

                {source &&
                    <>
                        {/* Section: File Details */}
                        <Grid item container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color='gray'>Dettagli File</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.nomeFile.name}
                                    name={metadataNotes.nomeFile.id}
                                    id={metadataNotes.nomeFile.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.nomeFile.required}
                                    value={documentProperties.nomeFile}
                                    sx={{ backgroundColor: '#f5f5f5' }}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.nomeFile)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {documentProperties.formatoFile &&
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        label={metadataNotes.formatoFile.name}
                                        name={metadataNotes.formatoFile.id}
                                        id={metadataNotes.formatoFile.id}
                                        variant="outlined"
                                        fullWidth
                                        required={metadataNotes.formatoFile.required}
                                        value={documentProperties.formatoFile}
                                        sx={{ backgroundColor: '#f5f5f5' }}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => handleClickOpen(metadataNotes.formatoFile)}>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.nomeFileDestinazione.name}
                                    name={metadataNotes.nomeFileDestinazione.id}
                                    id={metadataNotes.nomeFileDestinazione.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.nomeFileDestinazione.required}
                                    value={documentProperties.nomeFileDestinazione}
                                    sx={{ backgroundColor: '#f5f5f5' }}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.nomeFileDestinazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.cartellaDestinazione.name}
                                    name={metadataNotes.cartellaDestinazione.id}
                                    id={metadataNotes.cartellaDestinazione.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.cartellaDestinazione.required}
                                    value={documentProperties.cartellaDestinazione}
                                    onChange={handleDocumentPropertyChange('cartellaDestinazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.cartellaDestinazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required={metadataNotes.classeDocumentale.required}>
                                    <InputLabel>{metadataNotes.classeDocumentale.name}</InputLabel>
                                    <Select
                                        name={metadataNotes.classeDocumentale.id}
                                        label={metadataNotes.classeDocumentale.name}
                                        value={documentProperties.classeDocumentale}
                                        onChange={handleDocumentPropertyChange('classeDocumentale')}
                                        input={
                                            <OutlinedInput
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.classeDocumentale)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value="CONTRATTI">CONTRATTI</MenuItem>
                                        <MenuItem value="OIL - MANDATI E REVERSALI">OIL - MANDATI E REVERSALI</MenuItem>
                                        <MenuItem value="ORDINI ACQUISTO NSO">ORDINI ACQUISTO NSO</MenuItem>
                                        <MenuItem value="LIBRI E REGISTRI CONTABILI">LIBRI E REGISTRI CONTABILI</MenuItem>
                                        <MenuItem value="DDT">DDT</MenuItem>
                                        <MenuItem value="FATTURE">FATTURE</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.dataDocumento.name}
                                    name={metadataNotes.dataDocumento.id}
                                    id={metadataNotes.dataDocumento.id}
                                    type="date"
                                    fullWidth
                                    required={metadataNotes.dataDocumento.required}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={documentProperties.dataDocumento}
                                    onChange={handleDocumentPropertyChange('dataDocumento')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.dataDocumento)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {source &&
                                <>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            label={metadataNotes.impronta.name}
                                            name={metadataNotes.impronta.id}
                                            id={metadataNotes.impronta.id}
                                            variant="outlined"
                                            fullWidth
                                            required={metadataNotes.impronta.required}
                                            value={documentProperties.impronta}
                                            onChange={handleDocumentPropertyChange('impronta')}
                                            sx={{ backgroundColor: '#f5f5f5' }}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.impronta)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            label={metadataNotes.algoritmo.name}
                                            name={metadataNotes.algoritmo.id}
                                            id={metadataNotes.algoritmo.id}
                                            variant="outlined"
                                            fullWidth
                                            required={metadataNotes.algoritmo.required}
                                            value={documentProperties.algoritmo}
                                            onChange={handleDocumentPropertyChange('algoritmo')}
                                            sx={{ backgroundColor: '#f5f5f5' }}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.algoritmo)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.identificativoDocumento.name}
                                    name={metadataNotes.identificativoDocumento.id}
                                    id={metadataNotes.identificativoDocumento.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.identificativoDocumento.required}
                                    value={documentProperties.identificativoDocumento}
                                    onChange={handleDocumentPropertyChange('identificativoDocumento')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.identificativoDocumento)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.versioneDocumento.name}
                                    name={metadataNotes.versioneDocumento.id}
                                    id={metadataNotes.versioneDocumento.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.versioneDocumento.required}
                                    value={documentProperties.versioneDocumento}
                                    onChange={handleDocumentPropertyChange('versioneDocumento')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.versioneDocumento)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* Section: File Formation And Registration */}
                        <Grid item container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color='gray'>Formazione e Registrazione File</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required={metadataNotes.modalitaFormazione.required}>
                                    <InputLabel>{metadataNotes.modalitaFormazione.name}</InputLabel>
                                    <Select
                                        label={metadataNotes.modalitaFormazione.name}
                                        name={metadataNotes.modalitaFormazione.id}
                                        value={documentProperties.modalitaFormazione}
                                        onChange={handleDocumentPropertyChange('modalitaFormazione')}
                                        input={
                                            <OutlinedInput
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.modalitaFormazione)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value="a">a</MenuItem>
                                        <MenuItem value="b">b</MenuItem>
                                        <MenuItem value="c">c</MenuItem>
                                        <MenuItem value="d">d</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required={metadataNotes.tipologiaFlusso.required}>
                                    <InputLabel>{metadataNotes.tipologiaFlusso.name}</InputLabel>
                                    <Select
                                        label={metadataNotes.tipologiaFlusso.name}
                                        name={metadataNotes.tipologiaFlusso.id}
                                        value={documentProperties.tipologiaFlusso}
                                        onChange={handleDocumentPropertyChange('tipologiaFlusso')}
                                        input={
                                            <OutlinedInput
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.tipologiaFlusso)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value="U">U</MenuItem>
                                        <MenuItem value="E">E</MenuItem>
                                        <MenuItem value="I">I</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required={metadataNotes.tipoRegistro.required}>
                                    <InputLabel>{metadataNotes.tipoRegistro.name}</InputLabel>
                                    <Select
                                        label={metadataNotes.tipoRegistro.name}
                                        name={metadataNotes.tipoRegistro.id}
                                        value={documentProperties.tipoRegistro}
                                        onChange={handleDocumentPropertyChange('tipoRegistro')}
                                        input={
                                            <OutlinedInput
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.tipoRegistro)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value="Nessuno">Nessuno</MenuItem>
                                        <MenuItem value="Protocollo Ordinario/Protocollo Emergenza">Protocollo Ordinario/Protocollo Emergenza</MenuItem>
                                        <MenuItem value="Repertorio/Registro">Repertorio/Registro</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.dataRegistrazione.name}
                                    name={metadataNotes.dataRegistrazione.id}
                                    id={metadataNotes.dataRegistrazione.id}
                                    type="date"
                                    fullWidth
                                    required={metadataNotes.dataRegistrazione.required}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={documentProperties.dataRegistrazione}
                                    onChange={handleDocumentPropertyChange('dataRegistrazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.dataRegistrazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.numeroDocumento.name}
                                    name={metadataNotes.numeroDocumento.id}
                                    id={metadataNotes.numeroDocumento.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.numeroDocumento.required}
                                    value={documentProperties.numeroDocumento}
                                    onChange={handleDocumentPropertyChange('numeroDocumento')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.numeroDocumento)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.codiceRegistro.name}
                                    name={metadataNotes.codiceRegistro.id}
                                    id={metadataNotes.codiceRegistro.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.codiceRegistro.required}
                                    value={documentProperties.codiceRegistro}
                                    onChange={handleDocumentPropertyChange('codiceRegistro')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.codiceRegistro)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.oggetto.name}
                                    name={metadataNotes.oggetto.id}
                                    id={metadataNotes.oggetto.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.oggetto.required}
                                    value={documentProperties.oggetto}
                                    onChange={handleDocumentPropertyChange('oggetto')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.oggetto)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* Section: Role and Subject */}
                        <Grid item container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color='gray'>Ruolo e Soggetto</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required={metadataNotes.ruolo.required}>
                                    <InputLabel>{metadataNotes.ruolo.name}</InputLabel>
                                    <Select
                                        label={metadataNotes.ruolo.name}
                                        name={metadataNotes.ruolo.id}
                                        value={documentProperties.ruolo}
                                        onChange={handleDocumentPropertyChange('ruolo')}
                                        input={
                                            <OutlinedInput
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.ruolo)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value="Assegnatario">Assegnatario</MenuItem>
                                        <MenuItem value="Autore">Autore</MenuItem>
                                        <MenuItem value="Destinatario">Destinatario</MenuItem>
                                        <MenuItem value="Mittente">Mittente</MenuItem>
                                        <MenuItem value="Operatore">Operatore</MenuItem>
                                        <MenuItem value="Produttore">Produttore</MenuItem>
                                        <MenuItem value="RGD">RGD</MenuItem>
                                        <MenuItem value="RSP">RSP</MenuItem>
                                        <MenuItem value="Soggetto che effettua la registrazione">Soggetto che effettua la registrazione</MenuItem>
                                        <MenuItem value="altro">altro</MenuItem>
                                        <MenuItem value="Amministrazione che effettua la registrazione">Amministrazione che effettua la registrazione</MenuItem>
                                        <MenuItem value="RUP">RUP</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required={metadataNotes.tipoSoggetto.required}>
                                    <InputLabel>{metadataNotes.tipoSoggetto.name}</InputLabel>
                                    <Select
                                        label={metadataNotes.tipoSoggetto.name}
                                        name={metadataNotes.tipoSoggetto.id}
                                        value={documentProperties.tipoSoggetto}
                                        onChange={handleDocumentPropertyChange('tipoSoggetto')}
                                        input={
                                            <OutlinedInput
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.tipoSoggetto)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value="PF">PF</MenuItem>
                                        <MenuItem value="PG">PG</MenuItem>
                                        <MenuItem value="PAI">PAI</MenuItem>
                                        <MenuItem value="PAE">PAE</MenuItem>
                                        <MenuItem value="SW">SW</MenuItem>
                                        <MenuItem value="AS">AS</MenuItem>
                                        <MenuItem value="RUP">RUP</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.cognome.name}
                                    name={metadataNotes.cognome.id}
                                    id={metadataNotes.cognome.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.cognome}
                                    onChange={handleDocumentPropertyChange('cognome')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.cognome)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.nome.name}
                                    name={metadataNotes.nome.id}
                                    id={metadataNotes.nome.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.nome}
                                    onChange={handleDocumentPropertyChange('nome')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.nome)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.denominazione.name}
                                    name={metadataNotes.denominazione.id}
                                    id={metadataNotes.denominazione.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.denominazione}
                                    onChange={handleDocumentPropertyChange('denominazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.denominazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.codiceFiscale.name}
                                    name={metadataNotes.codiceFiscale.id}
                                    id={metadataNotes.codiceFiscale.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.codiceFiscale}
                                    onChange={handleDocumentPropertyChange('codiceFiscale')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.codiceFiscale)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* Section: Process Details */}
                        <Grid item container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color='gray'>Dettagli Processo</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.indirizziDigitaliDiRiferimento.name}
                                    name={metadataNotes.indirizziDigitaliDiRiferimento.id}
                                    id={metadataNotes.indirizziDigitaliDiRiferimento.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.indirizziDigitaliDiRiferimento}
                                    onChange={handleDocumentPropertyChange('indirizziDigitaliDiRiferimento')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.indirizziDigitaliDiRiferimento)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.allegatiNumero.name}
                                    name={metadataNotes.allegatiNumero.id}
                                    id={metadataNotes.allegatiNumero.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.allegatiNumero.required}
                                    value={documentProperties.allegatiNumero}
                                    onChange={handleDocumentPropertyChange('allegatiNumero')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.allegatiNumero)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.IdDocIndiceAllegati.name}
                                    name={metadataNotes.IdDocIndiceAllegati.id}
                                    id={metadataNotes.IdDocIndiceAllegati.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.IdDocIndiceAllegati}
                                    onChange={handleDocumentPropertyChange('IdDocIndiceAllegati')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.IdDocIndiceAllegati)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.descrizioneAllegati.name}
                                    name={metadataNotes.descrizioneAllegati.id}
                                    id={metadataNotes.descrizioneAllegati.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.descrizioneAllegati}
                                    onChange={handleDocumentPropertyChange('descrizioneAllegati')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.descrizioneAllegati)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.indiceDiClassificazione.name}
                                    name={metadataNotes.indiceDiClassificazione.id}
                                    id={metadataNotes.indiceDiClassificazione.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.indiceDiClassificazione}
                                    onChange={handleDocumentPropertyChange('indiceDiClassificazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.indiceDiClassificazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.descrizioneClassificazione.name}
                                    name={metadataNotes.descrizioneClassificazione.id}
                                    id={metadataNotes.descrizioneClassificazione.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.descrizioneClassificazione}
                                    onChange={handleDocumentPropertyChange('descrizioneClassificazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.descrizioneClassificazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.riservato.name}
                                    name={metadataNotes.riservato.id}
                                    id={metadataNotes.riservato.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.riservato.required}
                                    value={documentProperties.riservato}
                                    onChange={handleDocumentPropertyChange('riservato')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.riservato)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.pianoClassificazione.name}
                                    name={metadataNotes.pianoClassificazione.id}
                                    id={metadataNotes.pianoClassificazione.id}
                                    variant="outlined"
                                    fullWidth
                                    required={metadataNotes.pianoClassificazione.required}
                                    value={documentProperties.pianoClassificazione}
                                    onChange={handleDocumentPropertyChange('pianoClassificazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.pianoClassificazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.prodottoSoftwareNome.name}
                                    name={metadataNotes.prodottoSoftwareNome.id}
                                    id={metadataNotes.prodottoSoftwareNome.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.prodottoSoftwareNome}
                                    onChange={handleDocumentPropertyChange('prodottoSoftwareNome')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.prodottoSoftwareNome)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.prodottoSoftwareVersione.name}
                                    name={metadataNotes.prodottoSoftwareVersione.id}
                                    id={metadataNotes.prodottoSoftwareVersione.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.prodottoSoftwareVersione}
                                    onChange={handleDocumentPropertyChange('prodottoSoftwareVersione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.prodottoSoftwareVersione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.prodottoSoftwareProduttore.name}
                                    name={metadataNotes.prodottoSoftwareProduttore.id}
                                    id={metadataNotes.prodottoSoftwareProduttore.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.prodottoSoftwareProduttore}
                                    onChange={handleDocumentPropertyChange('prodottoSoftwareProduttore')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.prodottoSoftwareProduttore)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.verificaFirmaDigitale.name}
                                    name={metadataNotes.verificaFirmaDigitale.id}
                                    id={metadataNotes.verificaFirmaDigitale.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.verificaFirmaDigitale}
                                    onChange={handleDocumentPropertyChange('verificaFirmaDigitale')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.verificaFirmaDigitale)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.verificaMarcaTemporale.name}
                                    name={metadataNotes.verificaMarcaTemporale.id}
                                    id={metadataNotes.verificaMarcaTemporale.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.verificaMarcaTemporale}
                                    onChange={handleDocumentPropertyChange('verificaMarcaTemporale')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.verificaMarcaTemporale)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.verificaSigillo.name}
                                    name={metadataNotes.verificaSigillo.id}
                                    id={metadataNotes.verificaSigillo.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.verificaSigillo}
                                    onChange={handleDocumentPropertyChange('verificaSigillo')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.verificaSigillo)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.verificaConformitaCopie.name}
                                    name={metadataNotes.verificaConformitaCopie.id}
                                    id={metadataNotes.verificaConformitaCopie.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.verificaConformitaCopie}
                                    onChange={handleDocumentPropertyChange('verificaConformitaCopie')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.verificaConformitaCopie)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.IdAggregazione.name}
                                    name={metadataNotes.IdAggregazione.id}
                                    id={metadataNotes.IdAggregazione.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.IdAggregazione}
                                    onChange={handleDocumentPropertyChange('IdAggregazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.IdAggregazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.IdentificativoDocumentoPrincipale.name}
                                    name={metadataNotes.IdentificativoDocumentoPrincipale.id}
                                    id={metadataNotes.IdentificativoDocumentoPrincipale.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.IdentificativoDocumentoPrincipale}
                                    onChange={handleDocumentPropertyChange('IdentificativoDocumentoPrincipale')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.IdentificativoDocumentoPrincipale)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel>{metadataNotes.tracciaturaModificheTipo.name}</InputLabel>
                                    <Select
                                        label={metadataNotes.tracciaturaModificheTipo.name}
                                        name={metadataNotes.tracciaturaModificheTipo.id}
                                        value={documentProperties.tracciaturaModificheTipo}
                                        onChange={handleDocumentPropertyChange('tracciaturaModificheTipo')}
                                        input={
                                            <OutlinedInput
                                                endAdornment={
                                                    <InputAdornment position="start">
                                                        <IconButton onClick={() => handleClickOpen(metadataNotes.tracciaturaModificheTipo)}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value="annullamento">annullamento</MenuItem>
                                        <MenuItem value="rettifica">rettifica</MenuItem>
                                        <MenuItem value="integrazione">integrazione</MenuItem>
                                        <MenuItem value="annotazione">annotazione</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.soggettoAutoreModifica.name}
                                    name={metadataNotes.soggettoAutoreModifica.id}
                                    id={metadataNotes.soggettoAutoreModifica.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.soggettoAutoreModifica}
                                    onChange={handleDocumentPropertyChange('soggettoAutoreModifica')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.soggettoAutoreModifica)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.tracciaturaModificheData.name}
                                    name={metadataNotes.tracciaturaModificheData.id}
                                    id={metadataNotes.tracciaturaModificheData.id}
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={documentProperties.tracciaturaModificheData}
                                    onChange={handleDocumentPropertyChange('tracciaturaModificheData')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.tracciaturaModificheData)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.tracciaturaModificheIdDocVersionePrecedente.name}
                                    name={metadataNotes.tracciaturaModificheIdDocVersionePrecedente.id}
                                    id={metadataNotes.tracciaturaModificheIdDocVersionePrecedente.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.tracciaturaModificheIdDocVersionePrecedente}
                                    onChange={handleDocumentPropertyChange('tracciaturaModificheIdDocVersionePrecedente')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.tracciaturaModificheIdDocVersionePrecedente)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.tempoConservazione.name}
                                    name={metadataNotes.tempoConservazione.id}
                                    id={metadataNotes.tempoConservazione.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.tempoConservazione}
                                    onChange={handleDocumentPropertyChange('tempoConservazione')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.tempoConservazione)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* Section: Additional Information */}
                        <Grid item container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color='gray'>Informazioni Aggiuntive</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label={metadataNotes.note.name}
                                    name={metadataNotes.note.id}
                                    id={metadataNotes.note.id}
                                    variant="outlined"
                                    fullWidth
                                    value={documentProperties.note}
                                    onChange={handleDocumentPropertyChange('note')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => handleClickOpen(metadataNotes.note)}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid >
            <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth='md'>
                <DialogTitle>{dialogContent.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogContent.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CDNFileForm;