// React
import React from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Material UI
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

// Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

// React Flow
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';

// A ---------------------------------------------------------------------- M

const AuthorizedKeysNode = ({ data }) => {
    const { conservSostL1 } = UserAuth();

    return (
        <Paper sx={{ p: '1%' }}>
            <Typography variant='h6' align='center' gutterBottom>
                <b>Sources</b>
            </Typography>
            {/* Total Number of Certifications */}
            <Typography variant='body1' align='center' gutterBottom>
                <b>Tot. {!conservSostL1 ? 'Certifications' : 'Uploads'}: {data.totalNumberOfCertificationsKeys}</b>
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><b>Key Name</b></TableCell>
                        <TableCell><b>N. {!conservSostL1 ? 'Certifications' : 'Uploads'}</b></TableCell>
                        <TableCell align="right"><b>Stream</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.authorizedKeysArray.map((key, index) => (
                        <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                            <TableCell>
                                {key.name}
                            </TableCell>
                            <TableCell>
                                {key.key_number_of_certifications}
                            </TableCell>
                            <TableCell align="right">{key.key_number_of_certifications > 0 ? (
                                <CheckCircleOutlineIcon sx={{ color: '#00c853', verticalAlign: 'middle', marginLeft: '5px' }} />
                            ) : (
                                <CancelIcon sx={{ color: '#b0b8b0', verticalAlign: 'middle', marginLeft: '5px' }} />
                            )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Handle type="source" position={Position.Right} id='b' />
        </Paper>
    );
};

export default AuthorizedKeysNode;