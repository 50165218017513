// React
import React from "react";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";

// A ---------------------------------------------------------------------- M

const InsertPassword = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const passwordForm = (
    <Box sx={{ p: isMobile ? "5%" : "2%" }}>
      <Grid
        container
        alignItems="center"
        justifyContent={isMobile ? "center" : "space-between"}
        mt={isMobile ? "5%" : "0%"}
        mb={isMobile ? "5%" : "1%"}
      >
        <Grid item>
          <Typography variant="h6">{props.text}</Typography>
        </Grid>
        {!isMobile && (
          <Grid item>
            <IconButton
              onClick={props.handleClose}
              edge="end"
              sx={{ color: "red" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <>
        <TextField
          margin="dense"
          required
          fullWidth
          id={`Password-${props.label}`}
          label={t("password")}
          name="Password"
          type="password"
          value={props.password}
          onChange={(e) => props.setPassword(e.target.value)}
        />
        {props.invalidPassword && (
          <Typography color="red">
            {t("invalid_password")}. {t("try_again")}
          </Typography>
        )}
      </>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          fullWidth
          variant="contained"
          disabled={!props.password}
          onClick={props.handleAction}
        >
          {props.action}
        </Button>
      </Box>
    </Box>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={props.open}
      onClose={props.handleClose}
      onOpen={() => props.setOpen(true)}
      sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      {passwordForm}
    </SwipeableDrawer>
  ) : (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth>
      {passwordForm}
    </Dialog>
  );
};

export default InsertPassword;
