// React
import React, { useEffect, useState } from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Material UI
import { ThemeProvider } from '@mui/material/styles';

// Themes
import { DefaultTheme, ArmilisTheme, AMGTheme, AlsaTheme, _jZP9Zja79jTheme } from 'themes/themeConfig';

// Layouts
import DefaultLayout from './layouts/DefaultLayout/DefaultLayout';
import ArmilisLayout from './layouts/ArmilisLayout/ArmilisLayout';
import AMGLayout from './layouts/AMGLayout/AMGLayout';
import AlsaLayout from './layouts/AlsaLayout/AlsaLayout';
import _jZP9Zja79jLayout from './layouts/TagsLayout/_jZP9Zja79jLayout/_jZP9Zja79jLayout';

// Breadcrumbs
import ORBreadcrumbs from './ORBreadcrumbs';

// A ---------------------------------------------------------------------- M

const LayoutSwitcher = ({ children, tag }) => {
    const { user, userCompany } = UserAuth();
    const [layoutConfig, setLayoutConfig] = useState({ layout: ArmilisLayout, theme: ArmilisTheme });

    useEffect(() => {
        const hostname = window.location.hostname;
        let layout, theme;

        if (hostname === 'nightly.twins.armilis.com' || hostname === 'twins.armilis.com') {
            if (user) {
                if (userCompany === 'Armilis') {
                    layout = ArmilisLayout;
                    theme = ArmilisTheme;
                } else if (userCompany === 'AMG') {
                    layout = AMGLayout;
                    theme = AMGTheme;
                } else if (userCompany === 'Alsa') {
                    layout = AlsaLayout;
                    theme = AlsaTheme;
                } else {
                    layout = ArmilisLayout;
                    theme = ArmilisTheme;
                }
            } else {
                if (tag === 'jZP9Zja79j' || tag === 'buIl6Jhi1o' || tag === 'VmRCI2xFOM') {
                    layout = _jZP9Zja79jLayout;
                    theme = _jZP9Zja79jTheme;
                } else {
                    layout = DefaultLayout;
                    theme = DefaultTheme;
                }
            }
        } else if (hostname === 'thinkingabout.life') {
            layout = AMGLayout;
            theme = AMGTheme;
        } else {
            if (user) {
                if (userCompany === 'Armilis') {
                    layout = ArmilisLayout;
                    theme = ArmilisTheme;
                } else if (userCompany === 'AMG') {
                    layout = AMGLayout;
                    theme = AMGTheme;
                } else if (userCompany === 'Alsa') {
                    layout = AlsaLayout;
                    theme = AlsaTheme;
                } else {
                    layout = ArmilisLayout;
                    theme = ArmilisTheme;
                }
            } else {
                if (tag === 'jZP9Zja79j' || tag === 'buIl6Jhi1o' || tag === 'VmRCI2xFOM') {
                    layout = _jZP9Zja79jLayout;
                    theme = _jZP9Zja79jTheme;
                } else {
                    layout = DefaultLayout;
                    theme = DefaultTheme;
                }
            }
        }

        setLayoutConfig({ layout, theme });
    }, [userCompany, tag]);

    const { layout: LayoutComponent, theme } = layoutConfig;

    return (
        <ThemeProvider theme={theme}>
            <LayoutComponent>
                {user && <ORBreadcrumbs />}
                {children}
            </LayoutComponent>
        </ThemeProvider>
    );
};

export default LayoutSwitcher;