// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import { NewCertConfirm, NewRequestConfirm } from "ui-components/ORFeedbacks";

// Functions
import { decryptSeedByPasswordHash4Ed25519 } from "SafeTwin/crypto/cryptoseed";
import genInfoRecord from "generator/InfoGenerator/genInfoRecord";
import sendCertificationRequest from "tag/sendCertificationRequest";
import completeCertificationRequest from "tag/completeCertificationRequest";

// A ---------------------------------------------------------------------- M

const InfoGenerator = ({
  tags,
  tag,
  types,
  setTypes,
  open,
  setOpen,
  handleOpenCertificationSuccessful,
  handleOpenCertificationError,
  request,
  setRequest,
}) => {
  const { user } = UserAuth();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [keypair, setKeyPair] = useState(null);
  const [note, setNote] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [tagSelectionOpen, setTagSelectionOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [approval, setApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const decrypt = () => {
      if (open) {
        const keypair = decryptSeedByPasswordHash4Ed25519(
          user.reloadUserInfo.passwordHash
        );
        setKeyPair(keypair);
      }
    };

    decrypt();
  }, [open]);

  useEffect(() => {
    if (request) {
      setNote(request.data.note);
      setIsPublic(request.public);
    } else {
      setNote("");
      setIsPublic(false);
    }
  }, [request]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    if (keypair) {
      try {
        if (tag) {
          const result = await genInfoRecord(
            isPublic,
            user.uid,
            keypair,
            note,
            tag
          );

          if (result.txid) {
            if (request) {
              await completeCertificationRequest(tag, request.id);
            }

            handleOpenCertificationSuccessful();

            if (types && !types.includes("info")) {
              setTypes([...types, "info"]);
            }
          } else {
            handleOpenCertificationError();
          }
        } else {
          if (selectedTag) {
            const result = await genInfoRecord(
              isPublic,
              user.uid,
              keypair,
              note,
              selectedTag
            );

            if (result.txid) {
              if (request) {
                await completeCertificationRequest(selectedTag, request.id);
              }

              handleOpenCertificationSuccessful();
            } else {
              handleOpenCertificationError();
            }
          } else {
            const result = await genInfoRecord(
              isPublic,
              user.uid,
              keypair,
              note
            );

            if (result.txid) {
              handleOpenCertificationSuccessful();
            } else {
              handleOpenCertificationError();
            }
          }
        }
      } catch (error) {
        handleOpenCertificationError();
      }
    }

    setIsLoading(false);
    handleReset();
  };

  const handleSendApprovalRequest = async () => {
    setApproval(false);
    setIsLoading(true);

    const requestData = {
      note: note,
    };

    const requestTag = tag || selectedTag;

    await sendCertificationRequest(
      "info",
      user.uid,
      isPublic,
      requestData,
      requestTag
    );

    setIsLoading(false);
    handleReset();
  };

  const handleReset = () => {
    setNote("");
    setSelectedTag("");
    setIsPublic(false);

    if (request) {
      setRequest();
    }

    setOpen(false);
  };

  const handleChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleOpen = () => {
    setTagSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleClose = () => {
    setTagSelectionOpen(false);
  };

  const InfoReportForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          margin="dense"
          id="Note"
          name="Note"
          label={t("note")}
          variant="outlined"
          required
          value={note}
          disabled={request}
          onChange={(e) => setNote(e.target.value)}
        />
      </Grid>
      {location.pathname === "/my-certifications" && tags && (
        <Grid item xs={12}>
          <Typography gutterBottom>{t("databox")}</Typography>
          <FormControl fullWidth>
            <Select
              id="selected-tag-info"
              value={selectedTag}
              displayEmpty
              onChange={handleChange}
              open={tagSelectionOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              <MenuItem value="">
                <Typography color="gray">{t("no_databox")}</Typography>
              </MenuItem>
              {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.nickname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isPublic}
                disabled={request}
                onChange={(event) => setIsPublic(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("mark_as_public")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleReset}
        onOpen={() => setOpen(true)}
        sx={{ "& .MuiDrawer-paper": { width: "100%", height: "90%" } }}
        PaperProps={{
          sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
        }}
      >
        <Puller />
        <Box sx={{ p: "5%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            mt="5%"
            mb="10%"
          >
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("certify_note")}
              </Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              {InfoReportForm}
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading || !note}
                    onClick={() => setConfirm(true)}
                  >
                    {t("certify")}
                  </Button>
                </Grid>
                {!request && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={
                        isLoading ||
                        (location.pathname === "/my-certifications" &&
                          (!note || !selectedTag)) ||
                        (location.pathname !== "/my-certifications" && !note)
                      }
                      onClick={() => setApproval(true)}
                    >
                      {t("send_approval_request")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </SwipeableDrawer>
      <NewCertConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        handleGenerate={handleGenerate}
      />
      <NewRequestConfirm
        approval={approval}
        setApproval={setApproval}
        handleSendApprovalRequest={handleSendApprovalRequest}
      />
    </>
  ) : (
    <>
      <Dialog open={open} onClose={handleReset} fullWidth maxWidth="lg">
        <Box sx={{ p: "2.5%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            mb="3%"
          >
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("certify_note")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleReset}
                edge="end"
                sx={{ color: "red" }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              {InfoReportForm}
              <Grid item container xs={12} spacing={1} mt="3%">
                <Grid item xs={12} sm={!request ? 6 : 12}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading || !note}
                    onClick={() => setConfirm(true)}
                  >
                    {t("certify")}
                  </Button>
                </Grid>
                {!request && (
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={
                        isLoading ||
                        (location.pathname === "/my-certifications" &&
                          (!note || !selectedTag)) ||
                        (location.pathname !== "/my-certifications" && !note)
                      }
                      onClick={() => setApproval(true)}
                    >
                      {t("send_approval_request")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </Dialog>
      <NewCertConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        handleGenerate={handleGenerate}
      />
      <NewRequestConfirm
        approval={approval}
        setApproval={setApproval}
        handleSendApprovalRequest={handleSendApprovalRequest}
      />
    </>
  );
};

export default InfoGenerator;
