// React
import React, { useState, useEffect } from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Material UI
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from "@mui/material/Switch";

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Components
import { LinearLoadingComponent } from 'ui-components/LoadingComponent';
import { NewCertConfirm, NewRequestReject } from 'ui-components/ORFeedbacks';

// Functions
import { decryptSeedByPasswordHash4Ed25519 } from 'SafeTwin/crypto/cryptoseed';
import genImageRecord from 'generator/ImageGenerator/genImageRecord';
import completeCertificationRequest from 'tag/completeCertificationRequest';
import getFile from 'storage/getFile';

// A ---------------------------------------------------------------------- M

const ImageGeneratorFrame = ({ tag, open, setOpen, handleOpenCertificationRejected, handleOpenCertificationSuccessful, handleOpenCertificationError, request }) => {
	const { user } = UserAuth();

	const [keypair, setKeyPair] = useState(null);
	const [source, setSource] = useState('');
	const [blobURL, setBlobURL] = useState('');
	const [downloadURL, setDownloadUrl] = useState('');
	const [caption, setCaption] = useState('');
	const [selectedImage, setSelectedImage] = useState(null);
	const [isPublic, setIsPublic] = useState(false);
	const [reject, setReject] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const handlePopstate = () => {
			if (open) handleReset();
		};

		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		}
	}, [open]);

	useEffect(() => {
		const decrypt = () => {
			if (open) {
				const keypair = decryptSeedByPasswordHash4Ed25519(user.reloadUserInfo.passwordHash);
				setKeyPair(keypair);
			}
		}

		decrypt();
	}, [open]);

	useEffect(() => {
		if (request) {
			const downloadURL = request.data.downloadURL;

			const fetchFile = async () => {
				const bytearray = await getFile(downloadURL);
				setSource(bytearray);
			};

			fetchFile();

			setDownloadUrl(downloadURL);
			setCaption(request.data.caption);
			setIsPublic(request.public);
		}
	}, [request]);

	const handleReject = () => {
		console.error("Error Code 1005 - USER_REJECTED");
		handleOpenCertificationRejected();
		setReject(false);
		handleReset();
		window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1005', message: 'USER_REJECTED' } }, '*');
	};

	const handleGenerate = async () => {
		setConfirm(false);
		setIsLoading(true);

		if (keypair) {
			try {
				if (tag) {
					const QuickSignData = await genImageRecord(isPublic, user.uid, keypair, source, downloadURL, caption, tag);

					if (QuickSignData.txid) {
						await completeCertificationRequest(tag, request.id);
						console.log(QuickSignData);
						handleOpenCertificationSuccessful();
						window.parent.postMessage({ type: 'QuickSignData', QuickSignData }, '*');
					} else {
						console.error("Error Code 1004 - CERT_STREAM_FAILED");
						handleOpenCertificationError();
						window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1004', message: 'CERT_STREAM_FAILED' } }, '*');
					}
				}
			} catch (error) {
				console.error("Error Code 1004 - CERT_STREAM_FAILED");
				handleOpenCertificationError();
				window.parent.postMessage({ type: 'QuickSignError', QuickSignError: { code: '1004', message: 'CERT_STREAM_FAILED' } }, '*');
			}
		}

		setIsLoading(false);
		handleReset();
	};

	const handleReset = () => {
		setSource('');
		setBlobURL('');
		URL.revokeObjectURL(blobURL);
		setDownloadUrl('');
		setCaption('');
		setIsPublic(false);
		setOpen(false);
		const fileInput = document.getElementById('certify-photo-home-button');
		if (fileInput) fileInput.value = null;
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
	};

	const handleCloseDialogImage = () => {
		setSelectedImage(null);
	};

	const ImageReportForm =
		<Box>
			<Grid item container justifyContent="center">
				<Button onClick={() => handleImageClick(downloadURL)} sx={{ mt: '1%', mb: '3%' }}>
					Open Image
				</Button>
			</Grid>
			<TextField
				margin="dense"
				id="Caption"
				label="Caption"
				name="Caption"
				variant="outlined"
				fullWidth
				value={caption}
				disabled
				onChange={(e) => setCaption(e.target.value)}
				rows={1}
				multiline
				sx={{ mt: '1%', mb: '2%' }}
			/>
			<FormGroup>
				<FormControlLabel
					control={
						<Switch
							checked={isPublic}
							disabled
							onChange={(event) => setIsPublic(event.target.checked)}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					}
					label="Mark as public"
				/>
			</FormGroup>
		</Box>

	return (
		<>
			<Dialog open={open} onClose={handleReset} fullScreen>
				<Box sx={{ p: '2.5%' }}>
					<Grid container alignItems='center' justifyContent='space-between' mb='3%'>
						<Grid item>
							<Typography variant='h5'>Certify Photo</Typography>
						</Grid>
					</Grid>
					{isLoading ? <LinearLoadingComponent /> :
						<>
							{ImageReportForm}
							<Grid container spacing={1} mt='1%'>
								<Grid item xs={12}>
									<Button
										fullWidth
										variant="contained"
										onClick={() => setReject(true)}
									>
										Reject
									</Button>
								</Grid>
								<Grid item xs={12}>
									<Button
										fullWidth
										variant="contained"
										disabled={isLoading || !source}
										onClick={() => setConfirm(true)}
									>
										Certify
									</Button>
								</Grid>
							</Grid>
						</>
					}
				</Box>
			</Dialog>
			<NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
			<NewRequestReject reject={reject} setReject={setReject} handleReject={handleReject} />
			<Dialog open={Boolean(selectedImage)} onClose={handleCloseDialogImage}>
				<>
					<IconButton
						sx={{
							position: 'absolute',
							top: '2%',
							right: '2%',
							zIndex: 1,
							color: 'red',
						}}
						onClick={handleCloseDialogImage}
					>
						<CloseOutlinedIcon fontSize='small' />
					</IconButton>
					<img src={selectedImage} alt="img" style={{ width: '100%', height: '100%' }} />
				</>
			</Dialog>
		</>
	);
};

export default ImageGeneratorFrame;