// React
import React, { useState } from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Icons
import AddIcon from '@mui/icons-material/Add';

// A ---------------------------------------------------------------------- M

const CertificationMenu = (props) => {
    const { conservSostL1 } = UserAuth();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCertify = (type) => {
        handleClose();
        switch (type) {
            case 'PDF':
                props.openDocGenDrawer();
                break;
            case 'Image':
                props.openImageGenDrawer();
                break;
            case 'Note':
                props.openInfoGenDrawer();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Button
                aria-controls="certification-menu"
                aria-haspopup="true"
                variant="contained"
                startIcon={<AddIcon />}
                fullWidth
                onClick={handleClick}
            >
                {conservSostL1 ? t('new_upload') : t('new_certification')}
            </Button>
            <Menu
                id="certification-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: anchorEl ? anchorEl.clientWidth : undefined,
                    }
                }}
            >
                <MenuItem onClick={() => handleCertify('PDF')}>{t('certify_document')}</MenuItem>
                <MenuItem onClick={() => handleCertify('Image')}>{t('certify_image')}</MenuItem>
                <MenuItem onClick={() => handleCertify('Note')}>{t('certify_note')}</MenuItem>
            </Menu>
        </>
    );
};

export default CertificationMenu;