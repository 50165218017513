// React
import React, { useEffect, useState } from "react";

// qrcode.react
import { QRCodeSVG } from "qrcode.react";

// i18n
import { useTranslation } from "react-i18next";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";

// Functions
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";
import createDataBox from "tag/createDataBox";
import addSeenTag from "user/addSeenTag";

// A ---------------------------------------------------------------------- M

const DataBoxCreate = ({ open, setOpen, handleSuccessful }) => {
  const { user, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [tagID, setTagID] = useState();
  const [loading, setLoading] = useState(false);

  const steps = [
    {
      label: `1. ${t("name")}`,
      description: t("new_databox_1"),
    },
    {
      label: `2. ${t("description")}`,
      description: t("new_databox_2"),
    },
    {
      label: `3. ${t("qr_code")}`,
      description: !conservSostL1 ? t("new_databox_3") : t("new_databox_4"),
    },
  ];

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextGenerateString = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    generateNewString();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const generateNewString = () => {
    const tagID = genRndStringPrintable(10);
    setTagID(tagID);
  };

  const generateNewTag = async () => {
    setLoading(true);
    await createDataBox(tagID, user.uid, name);
    await addSeenTag(tagID, user.uid, name, notes);
    setLoading(false);
    handleReset();
    handleSuccessful();
  };

  const handleReset = () => {
    setName("");
    setNotes("");
    setTagID("");
    setActiveStep(0);
    setLoading(false);
    setOpen(false);
  };

  if (isMobile) {
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleReset}
        onOpen={() => setOpen(true)}
        sx={{ "& .MuiDrawer-paper": { width: { xs: "100%", md: "30%" } } }}
        PaperProps={{
          sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
        }}
      >
        <Puller />
        <Box sx={{ p: "5%" }}>
          <Grid container alignItems="center" justifyContent="center" mt="5%">
            <Grid item>
              <Typography variant="h6" fontWeight="bold">
                {conservSostL1 ? t("new_archive") : t("new_databox")}
              </Typography>
            </Grid>
          </Grid>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step key={steps[0].label}>
              <StepLabel>
                <b>{steps[0].label}</b>
              </StepLabel>
              <StepContent>
                <Typography>{steps[0].description}</Typography>
                <TextField
                  margin="dense"
                  id="Name"
                  label={t("name")}
                  name="Name"
                  fullWidth
                  required
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleNext}
                    disabled={!name}
                  >
                    {t("continue")}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step key={steps[1].label}>
              <StepLabel>
                <b>{steps[1].label}</b>
              </StepLabel>
              <StepContent>
                <Typography>{steps[1].description}</Typography>
                <TextField
                  margin="dense"
                  id="Description"
                  label={t("description")}
                  name="Description"
                  fullWidth
                  variant="outlined"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={2}
                  multiline
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("back")}
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleNextGenerateString}
                  >
                    {t("continue")}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step key={steps[2].label}>
              <StepLabel>
                <b>{steps[2].label}</b>
              </StepLabel>
              <StepContent>
                <Typography>{steps[2].description}</Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "center", mb: "5%" }}
                >
                  <QRCodeSVG
                    value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tagID}`}
                    style={{ height: 150, width: 150 }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("back")}
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={generateNewTag}
                  >
                    {t("continue")}
                  </Button>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Dialog open={open} onClose={handleReset} fullWidth maxWidth="md">
        <Box sx={{ p: "2%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            mb="5%"
          >
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {conservSostL1 ? t("new_archive") : t("new_databox")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleReset}
                edge="end"
                sx={{ color: "red" }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step key={steps[0].label}>
              <StepLabel>
                <b>{steps[0].label}</b>
              </StepLabel>
              <StepContent>
                <Typography>{steps[0].description}</Typography>
                <TextField
                  margin="dense"
                  id="Name"
                  label={t("name")}
                  name="Name"
                  fullWidth
                  required
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleNext}
                    disabled={!name}
                  >
                    {t("continue")}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step key={steps[1].label}>
              <StepLabel>
                <b>{steps[1].label}</b>
              </StepLabel>
              <StepContent>
                <Typography>{steps[1].description}</Typography>
                <TextField
                  margin="dense"
                  id="Description"
                  label={t("description")}
                  name="Description"
                  fullWidth
                  variant="outlined"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={2}
                  multiline
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("back")}
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleNextGenerateString}
                  >
                    {t("continue")}
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step key={steps[2].label}>
              <StepLabel>
                <b>{steps[2].label}</b>
              </StepLabel>
              <StepContent>
                <Typography mb="5%">{steps[2].description}</Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "center", mb: "5%" }}
                >
                  <QRCodeSVG
                    value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tagID}`}
                    style={{ height: 150, width: 150 }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("back")}
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth={isMobile}
                    onClick={generateNewTag}
                  >
                    {t("create")}
                  </Button>
                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Box>
      </Dialog>
    );
  }
};

export default DataBoxCreate;
