// React
import React, { useEffect, useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import getTagTypes from "tag/getTagTypes";
import addForwardRoute from "tag/addForwardRoute";

// A ---------------------------------------------------------------------- M

const NewTargetEndpoint = ({ tag, open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [endpoint, setEndpoint] = useState("");
  const [type, setType] = useState("REST");
  const [status, setStatus] = useState(true);
  const [types, setTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [headers, setHeaders] = useState([{ key: "", value: "" }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const fetch = async () => {
      const types = await getTagTypes(tag.id);
      setTypes(types);
    };

    fetch();
  }, [tag]);

  const handleReset = () => {
    setEndpoint("");
    setType("REST");
    setStatus(true);
    setSelectedTypes([]);
    setHeaders([{ key: "", value: "" }]);
    setOpen(false);
  };

  const submitForwardRoute = async () => {
    setLoading(true);

    const body = {
      tag: tag,
      endpoint: endpoint,
      type: type,
      status: status,
      selectedTypes: selectedTypes,
      headers: headers,
    };

    await addForwardRoute(tag.id, body);

    setLoading(false);
    handleReset();
  };

  const handleAddHeader = () => {
    setHeaders([...headers, { key: "", value: "" }]);
  };

  const handleRemoveHeader = (index) => {
    const newHeaders = headers.filter((_, idx) => idx !== index);
    setHeaders(newHeaders);
  };

  const handleHeaderChange = (index, event) => {
    const { name, value } = event.target;
    const newHeaders = headers.map((header, idx) => {
      if (idx === index) {
        return { ...header, [name]: value };
      }
      return header;
    });
    setHeaders(newHeaders);
  };

  const TargetForm = (
    <Box sx={{ p: isMobile ? "5%" : "3%" }}>
      <Grid
        container
        alignItems="center"
        justifyContent={isMobile ? "center" : "space-between"}
        mt={isMobile ? "5%" : "0%"}
      >
        <Grid item>
          <Typography variant="h5" fontWeight="bold">
            {t("add_endpoint")}
          </Typography>
        </Grid>
        {!isMobile && (
          <Grid item>
            <IconButton onClick={handleReset} sx={{ color: "red" }} edge="end">
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <TextField
        margin="dense"
        id="endpoint"
        label={t("endpoint")}
        name="Endpoint"
        required
        value={endpoint}
        onChange={(e) => setEndpoint(e.target.value)}
        fullWidth
        sx={{ mt: "1%", mb: "2%" }}
      />
      <FormControl fullWidth sx={{ mt: "1%", mb: "2%" }} required>
        <InputLabel id="type-select-label">{t("type")}</InputLabel>
        <Select
          labelId="type-select-label"
          id="type"
          label={t("type")}
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={"REST"}>REST</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mt: "1%", mb: "2%" }} required>
        <InputLabel id="status-select-label">{t("status")}</InputLabel>
        <Select
          labelId="status-select-label"
          id="status"
          label={t("status")}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <MenuItem value={true}>{t("enabled").toUpperCase()}</MenuItem>
          <MenuItem value={false}>{t("disabled").toUpperCase()}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mt: "1%", mb: "2%" }} required>
        <Select
          id="data-types"
          displayEmpty
          multiple
          value={selectedTypes}
          onChange={(e) => setSelectedTypes(e.target.value)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography color="gray">{t("choose_datatypes")}</Typography>
              );
            }

            return selected.join(", ");
          }}
        >
          {types.length !== 0 &&
            types.map((type, index) => (
              <MenuItem key={index} value={type}>
                <Checkbox checked={selectedTypes.indexOf(type) > -1} />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Typography variant="h6" color="gray" sx={{ mt: "2%", mb: "1%" }}>
        Headers
      </Typography>
      {headers.map((header, index) => (
        <Grid
          container
          spacing={1}
          alignItems="center"
          key={index}
          sx={{ mt: "1%", mb: "2%" }}
        >
          <Grid item xs={5.5}>
            <TextField
              label={t("key")}
              name="key"
              required
              value={header.key}
              onChange={(e) => handleHeaderChange(index, e)}
              fullWidth
            />
          </Grid>
          <Grid item xs={5.5}>
            <TextField
              label={t("value")}
              name="value"
              required
              value={header.value}
              onChange={(e) => handleHeaderChange(index, e)}
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => handleRemoveHeader(index)}
              sx={{ color: "red" }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleAddHeader}
        fullWidth
        sx={{ mt: "1%", mb: "2%" }}
      >
        {t("add_header")}
      </Button>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          fullWidth
          variant="contained"
          onClick={submitForwardRoute}
          disabled={!endpoint}
        >
          {t("add")}
        </Button>
      </Box>
    </Box>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      {loading ? <LoadingComponent /> : TargetForm}
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} fullWidth maxWidth="lg">
      {loading ? <LoadingComponent /> : TargetForm}
    </Dialog>
  );
};

export default NewTargetEndpoint;
