// React
import React, { useState, useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import addMember from "group/addMember";
import getUUIDFromEmail from "user/getUUIDFromEmail";

// A ---------------------------------------------------------------------- M

const AddMember = ({
  group,
  open,
  setOpen,
  handleSuccessfulAdded,
  handleErrorAdded,
}) => {
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const [userAlreadyAdded, setUserAlreadyAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    setUserNotFound(false);
    setUserAlreadyAdded(false);
  }, [newMemberEmail]);

  const handleAddMember = async () => {
    setIsLoading(true);

    try {
      const newMemberUID = await getUUIDFromEmail(newMemberEmail);

      if (!newMemberUID) {
        setUserNotFound(true);
        setIsLoading(false);
        return;
      }

      const result = await addMember(user.uid, newMemberUID, group.id);

      switch (result.status) {
        case "success":
          handleReset();
          handleSuccessfulAdded();
          break;
        case "user_already_added":
          setUserAlreadyAdded(true);
          break;
        case "error":
          handleErrorAdded();
          break;
        default:
          handleErrorAdded();
      }
    } catch (error) {
      handleErrorAdded();
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setNewMemberEmail("");
    setUserNotFound(false);
    setUserAlreadyAdded(false);
    setOpen(false);
  };

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{ "& .MuiDrawer-paper": { width: "100%", height: "45%" } }}
      PaperProps={{
        sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          mt="5%"
          mb="10%"
        >
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("add_member")}
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <TextField
              fullWidth
              margin="dense"
              label={t("email")}
              name="Email Address"
              id="Email Address"
              required
              variant="outlined"
              value={newMemberEmail}
              onChange={(e) => setNewMemberEmail(e.target.value)}
            />
            {userNotFound && (
              <Typography variant="body2" color="red" gutterBottom>
                {t("member_error_1")}
              </Typography>
            )}
            {userAlreadyAdded && (
              <Typography variant="body2" color="gray" gutterBottom>
                {t("member_error_2")}
              </Typography>
            )}
            <Grid item mt="5%">
              <Button fullWidth variant="contained" onClick={handleAddMember}>
                {t("add")}
              </Button>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} fullWidth maxWidth="md">
      <Box sx={{ p: "2.5%" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb="3%"
        >
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("add_member")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <TextField
              fullWidth
              margin="dense"
              label={t("email")}
              name="Email Address"
              id="Email Address"
              required
              variant="outlined"
              value={newMemberEmail}
              onChange={(e) => setNewMemberEmail(e.target.value)}
            />
            {userNotFound && (
              <Typography variant="body2" color="red" gutterBottom>
                {t("member_error_1")}
              </Typography>
            )}
            {userAlreadyAdded && (
              <Typography variant="body2" color="red" gutterBottom>
                {t("member_error_2")}
              </Typography>
            )}
            <Grid item mt="3%">
              <Button
                fullWidth
                variant="contained"
                onClick={handleAddMember}
                disabled={!newMemberEmail}
              >
                {t("add")}
              </Button>
            </Grid>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default AddMember;
