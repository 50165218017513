// React
import React, { useState, useEffect } from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Components
import ImageTable from './ImageTable';
import DatapointIntegrityInspector from 'ui-components/DatapointIntegrityInspector';
import { LoadingDialog } from 'ui-components/LoadingComponent';

// Firebase
import { db } from 'config/firebase';
import { doc, collection, onSnapshot, query, where } from 'firebase/firestore';

// Functions
import fetchRecordsByType from 'record/fetchRecordsByType';
import verifySignature from 'verificator/verifySignature';

// A ---------------------------------------------------------------------- M

const ImageViewer = ({ tag, isTagGroupMember }) => {
    const { user } = UserAuth();

    const [records, setRecords] = useState([]);
    const [record, setRecord] = useState();
    const [verification, setVerification] = useState();
    const [open, setOpen] = useState(false);
    const [verificationLoading, setVerificationLoading] = useState(false);

    useEffect(() => {
        const handlePopState = () => {
            if (open) {
                setOpen(false);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        }
    }, [open]);

    useEffect(() => {
        const tagsdataDocRef = doc(db, 'tagsdata', tag);
        const signaturesCollectionRef = collection(tagsdataDocRef, 'signatures');
        const currentTimestampInSecondsString = (Date.now() / 1000).toString();

        const newAdditionsQuery = query(
            signaturesCollectionRef,
            where('type', '==', 'img'),
            where('timestamp', '>', currentTimestampInSecondsString)
        );

        const newAdditionsUnsubscribe = onSnapshot(newAdditionsQuery, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    getRecords();
                }
            });
        });

        const getRecords = async () => {
            const records = await fetchRecordsByType(tag, 'img', isTagGroupMember);
            records.sort((a, b) => b.data.timestamp - a.data.timestamp);
            setRecords(records);
        };

        getRecords();

        return () => {
            newAdditionsUnsubscribe();
        };
    }, [tag, isTagGroupMember]);

    const handleOpen = () => {
        setOpen(true);
        window.history.pushState(null, '');
    };

    const checkVerification = async (record) => {
        try {
            setVerificationLoading(true);

            let verification;

            if (user) {
                verification = await verifySignature(user.uid, record, record.type);
            } else {
                verification = await verifySignature(false, record, record.type);
            }

            setRecord(record);
            setVerification(verification);
            handleOpen();
        } catch (e) {
            console.error("Error in checkVerification:", e.message);
        } finally {
            setVerificationLoading(false);
        }
    };

    return (
        <>
            {verificationLoading && <LoadingDialog open={verificationLoading} />}

            {records.length !== 0 &&
                <ImageTable
                    records={records}
                    checkVerification={checkVerification}
                    isTagGroupMember={isTagGroupMember}
                />
            }

            {record && verification &&
                <DatapointIntegrityInspector
                    record={record}
                    verification={verification}
                    open={open}
                    setOpen={setOpen} />
            }
        </>
    );
};

export default ImageViewer;