// Firebase
import { db } from "config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Retrieves an array of data keys belonging to a specified owner.
 *
 * This function searches the 'keydata' collection for documents where the 'owner_uuid' matches
 * the provided uuid. For each found document, it also retrieves the corresponding document from
 * the 'keysecrets' collection using the document ID. Each document's ID, name, created_on attributes
 * from 'keydata', and the datakey data from 'keysecrets' are collected into an array.
 *
 * @param {string} uuid - The uuid of the owner for whom to retrieve data keys and their secret datakeys.
 * @returns {Promise<Array<{id: string, name: string, created_on: string, datakey: string}>>} An array of objects,
 * each representing a data key along with its secret datakey. Each object contains the data key's ID, name, created_on date,
 * and the datakey data. If an error occurs, an empty array is returned.
 */
const getDataKeysByOwner = async (uuid) => {
  try {
    const keyDataCollectionRef = collection(db, "keydata");
    const q = query(keyDataCollectionRef, where("owner_uuid", "==", uuid));
    const snapshot = await getDocs(q);

    const keysPromises = snapshot.docs.map(async (key) => {
      const keySecretDocRef = doc(db, "keysecrets", key.id);
      const keySecretSnapshot = await getDoc(keySecretDocRef);
      const keySecretData = keySecretSnapshot.exists()
        ? keySecretSnapshot.data()
        : {};

      return {
        id: key.id,
        name: key.data().name || "",
        created_on: key.data().created_on || "",
        datakey: keySecretData.datakey || "",
      };
    });

    const keys = await Promise.all(keysPromises);
    return keys;
  } catch (e) {
    console.error("Error in getDataKeysByOwner:", e.message);
    return [];
  }
};

export default getDataKeysByOwner;
