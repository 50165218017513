// React
import React, { useState } from "react";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

// Components
import { Puller } from "ui-components/Puller";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CertificationView = ({ record, open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseDialogImage = () => {
    setSelectedImage(null);
  };

  const sortedKeys = Object.keys(record.data).sort();

  const detailsForm = (
    <Box sx={{ p: isMobile ? "5%" : "2%" }}>
      <Grid container spacing={2}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent={isMobile ? "center" : "space-between"}
          mt={isMobile ? "5%" : "0%"}
          mb={isMobile ? "5%" : "1%"}
        >
          <Grid item>
            <Typography variant="h5">{t("details")}</Typography>
          </Grid>
          {!isMobile && (
            <Grid item>
              <IconButton
                onClick={handleClose}
                edge="end"
                sx={{ color: "red" }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={1}>
          {record.type === "doc" && (
            <Grid
              item
              container
              xs={12}
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              <Button
                onClick={() => handleFileClick(record.data.attachment)}
                color="primary"
                variant="contained"
              >
                {t("open_document")}
              </Button>
            </Grid>
          )}
          {record.type === "img" && (
            <Grid
              item
              container
              xs={12}
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              <Button
                onClick={() => handleImageClick(record.data.attachment)}
                color="primary"
                variant="contained"
              >
                {t("open_image")}
              </Button>
            </Grid>
          )}
          <>
            {sortedKeys
              .filter((key) => key !== "attachment")
              .map((key) => (
                <Grid item xs={12} key={key}>
                  <Typography gutterBottom>
                    <strong>
                      {key.charAt(0).toUpperCase() + key.slice(1)}:
                    </strong>{" "}
                    {key === "timestamp"
                      ? convertTimestamp(record.data[key])
                      : record.data[key]}
                  </Typography>
                </Grid>
              ))}
          </>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          onOpen={() => setOpen(true)}
          sx={{ "& .MuiDrawer-paper": { width: "100%", height: "45%" } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          <Puller />
          {detailsForm}
        </SwipeableDrawer>
      ) : (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          {detailsForm}
        </Dialog>
      )}

      {selectedFile && (
        <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog}>
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document
            file={selectedFile}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width={isMobile ? "300" : "600"}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === numPages}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}

      <Dialog open={Boolean(selectedImage)} onClose={handleCloseDialogImage}>
        <>
          <IconButton
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              zIndex: 1,
              color: "red",
            }}
            onClick={handleCloseDialogImage}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
          <img
            src={selectedImage}
            alt="img"
            style={{ width: "100%", height: "100%" }}
          />
        </>
      </Dialog>
    </>
  );
};

export default CertificationView;
