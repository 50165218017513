// React
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

// Icons
import FolderIcon from '@mui/icons-material/Folder';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApiIcon from '@mui/icons-material/Api';
import KeyIcon from '@mui/icons-material/VpnKey';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

// Components
import Copyright from '../../Copyright';

// A ---------------------------------------------------------------------- M

const NavigationItem = ({ onClick, icon, text }) => (
    <ListItem
        onClick={onClick}
        sx={{
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
            }
        }}
    >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
    </ListItem>
);

const AlsaSidebar = ({ drawerWidth, openQRCodeScannerDrawer, handleDrawerToggle, drawerOpen }) => {
    const { user, logout, isReseller, canAddUser, conservSostL1 } = UserAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/signin');
        } catch (e) {
            console.error('Error in logout:', e.message);
        }
    };

    const handleSignIn = () => {
        navigate('/signin');
    };

    let menuItems = [
        { text: conservSostL1 ? t('my_archives') : t('my_databoxes'), icon: <FolderIcon sx={{ color: 'black' }} />, route: '/' },
        { text: conservSostL1 ? t('my_documents') : t('my_certifications'), icon: <PlaylistAddCheckOutlinedIcon sx={{ color: 'black' }} />, route: '/my-certifications' },
        { text: t('my_groups'), icon: <GroupsIcon sx={{ color: 'black' }} />, route: '/my-groups' },
        { isDivider: true },
        { text: t('scan_qr_code'), icon: <QrCodeScannerIcon sx={{ color: 'black' }} />, onClick: openQRCodeScannerDrawer },
        { text: t('my_account'), icon: <AccountCircleIcon sx={{ color: 'black' }} />, route: '/my-account' },
        isReseller ? { text: t('reseller_panel'), icon: <ApiIcon sx={{ color: 'black' }} />, route: '/reseller-panel' } : null,
        !conservSostL1 && { text: t('generate_keypair'), icon: <KeyIcon sx={{ color: 'black' }} />, route: '/keypair-generator' },
        canAddUser ? { text: t('add_new_user'), icon: <PersonAddIcon sx={{ color: 'black' }} />, route: '/new-user' } : null,
        { text: t('logout'), icon: <LogoutIcon sx={{ color: 'black' }} />, onClick: handleLogout },
    ].filter(Boolean);

    const handleItemClick = (item) => {
        if (item.route && location.pathname === item.route) {
            if (isMobile) {
                handleDrawerToggle();
            }
        } else if (item.route) {
            if (isMobile) {
                handleDrawerToggle();
            }
            navigate(item.route);
        } else if (item.onClick) {
            item.onClick();
        }
    };

    return (
        <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={isMobile ? drawerOpen : true}
            onClose={handleDrawerToggle}
            sx={{
                width: drawerWidth,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: '#eceff1',
                    color: '#37474f'
                },
            }}
        >
            <Toolbar />
            {user ?
                <List>
                    {menuItems.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.isDivider ? (
                                <Divider />
                            ) : (
                                <NavigationItem
                                    onClick={() => handleItemClick(item)}
                                    icon={item.icon}
                                    text={item.text}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </List> :
                <List>
                    <NavigationItem
                        key="Sign In"
                        onClick={handleSignIn}
                        icon={<LoginIcon sx={{ color: 'black' }} />}
                        text={t('sign_in')}
                    />
                    <NavigationItem
                        key="Keypair Generator"
                        onClick={() => navigate('/keypair-generator')}
                        icon={<KeyIcon sx={{ color: 'black' }} />}
                        text={t('generate_keypair')}
                    />
                </List>
            }
            <Box sx={{ p: 2, mt: 'auto', backgroundColor: '#cfd8dc' }}>
                <Copyright />
            </Box>
        </Drawer>
    );
};

export default AlsaSidebar;