// Firebase
import { db } from "config/firebase";
import { collection, updateDoc, doc } from "firebase/firestore";

// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Updates a forward route to the Firestore database.
 * Updates the document in the 'forward_routes' subcollection of the specified data box document.
 *
 * @param {string} tagID - The unique identifier of the data box document in Firestore.
 * @param {Object} body - The data to be set to the updated document.
 * @param {string} body.endpoint - The endpoint URL of the forward route.
 * @param {string} body.type - The type URL of the forward route (e.g. REST).
 * @param {Boolean} body.status - The status of the forward route (true/false).
 * @param {Array<string>} body.selectedTypes - An array of selected types for the forward route.
 */
const updateForwardRoute = async (tagID, route, body) => {
  try {
    const { endpoint, type, status, selectedTypes } = body;

    const tagRef = doc(db, "tagsdata", tagID);
    const forwardRoutesRef = collection(tagRef, "forward_routes");
    const forwardRouteDocRef = doc(forwardRoutesRef, route);

    await updateDoc(forwardRouteDocRef, {
      endpoint: endpoint,
      type: type,
      enabled: status,
      types: selectedTypes,
      last_modified: unixTimestampInSeconds(),
    });
  } catch (e) {
    console.error("Error in updateForwardRoute:", e.message);
  }
};

export default updateForwardRoute;
