// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Context
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import {
  NewCertConfirm,
  NewRequestConfirm,
  InvalidFile,
} from "ui-components/ORFeedbacks";
import CDNFileForm from "./CDNFileForm";
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";

// Functions
import fileToUint8Array from "utils/fileToUint8Array";
import getFile from "storage/getFile";
import uploadFile from "storage/uploadFile";
import genCDNFileRecord from "generator/CDNFileGenerator/genCDNFileRecord";
import completeCertificationRequest from "tag/completeCertificationRequest";

// SafeTwin
import { calculateSHA256, toHex } from "SafeTwin/crypto/cryptolibsodium";

// Firebase
import { db } from "config/firebase";
import { onSnapshot, doc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const initialDocumentProperties = {
  nomeFile: "",
  formatoFile: "",
  nomeFileDestinazione: "",
  cartellaDestinazione: "",
  classeDocumentale: "",
  dataDocumento: "",
  impronta: "",
  algoritmo: "",
  identificativoDocumento: "",
  versioneDocumento: "",
  modalitaFormazione: "",
  tipologiaFlusso: "",
  tipoRegistro: "",
  dataRegistrazione: "",
  numeroDocumento: "",
  codiceRegistro: "",
  oggetto: "",
  ruolo: "",
  tipoSoggetto: "",
  cognome: "",
  nome: "",
  denominazione: "",
  codiceFiscale: "",
  indirizziDigitaliDiRiferimento: "",
  allegatiNumero: "",
  IdDocIndiceAllegati: "",
  descrizioneAllegati: "",
  indiceDiClassificazione: "",
  descrizioneClassificazione: "",
  riservato: "",
  pianoClassificazione: "",
  prodottoSoftwareNome: "",
  prodottoSoftwareVersione: "",
  prodottoSoftwareProduttore: "",
  verificaFirmaDigitale: "",
  verificaMarcaTemporale: "",
  verificaSigillo: "",
  verificaConformitaCopie: "",
  IdAggregazione: "",
  IdentificativoDocumentoPrincipale: "",
  tracciaturaModificheTipo: "",
  soggettoAutoreModifica: "",
  tracciaturaModificheData: "",
  tracciaturaModificheIdDocVersionePrecedente: "",
  tempoConservazione: "",
  note: "",
};

const CDNFileGenerator = ({
  tags,
  tag,
  types,
  setTypes,
  open,
  setOpen,
  handleOpenCertificationError,
  request,
  setRequest,
}) => {
  const { user } = UserAuth();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [source, setSource] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [downloadURL, setDownloadUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentProperties, setDocumentProperties] = useState(
    initialDocumentProperties
  );
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tagSelectionOpen, setTagSelectionOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [approval, setApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [uploadReceiptTXID, setUploadReceiptTXID] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    if (request) {
      const downloadURL = request.data.downloadURL;
      const fileName = request.data.fileName;
      const fileType = request.data.fileType;

      const fetchFile = async () => {
        const bytearray = await getFile(downloadURL);
        setSource(bytearray);
      };

      fetchFile();
      setDownloadUrl(downloadURL);
      setFileName(fileName);
      setFileType(fileType);
    } else {
      setSource("");
      setDownloadUrl("");
      setFileName("");
      setFileType("");
    }
  }, [request]);

  useEffect(() => {
    if (!uploadReceiptTXID) return;

    const unsubscribe = onSnapshot(
      doc(db, "uploadReceipts", uploadReceiptTXID),
      (doc) => {
        if (doc.exists()) {
          console.log("Upload receipt:", doc.data());
          setUploadReceipt(doc.data());
          setReceiptDialogOpen(true);
        }
      }
    );

    return () => unsubscribe();
  }, [uploadReceiptTXID]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    try {
      let result;
      let downloadURL;
      let uploadID;

      if (request) {
        downloadURL = request.data.downloadURL;
      } else {
        const uploadFileResult = await uploadFile(
          source,
          fileName,
          fileType,
          tag
        );
        downloadURL = uploadFileResult.downloadURL;
        uploadID = uploadFileResult.uploadID;
      }

      if (downloadURL) {
        const dataBody = {
          source,
          downloadURL,
          uploadID,
          fileName,
          fileType,
          documentProperties,
        };

        if (tag) {
          result = await genCDNFileRecord(user.uid, dataBody, tag);
        } else if (selectedTag) {
          result = await genCDNFileRecord(user.uid, dataBody, selectedTag);
        }

        if (result.success) {
          if (request) {
            await completeCertificationRequest(tag, request.id);
          }

          setUploadReceiptTXID(result.txid);

          if (types && !types.includes("CDNFile")) {
            setTypes([...types, "CDNFile"]);
          }
        } else {
          handleOpenCertificationError();
        }
      } else {
        console.error("Invalid file format");
        setIsInvalidFile(true);
        resetFileInput();
      }
    } catch (error) {
      console.error("Error in handleGenerate:", error.message);
      handleOpenCertificationError();
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const handleSendApprovalRequest = async () => {
    setIsLoading(true);
    console.log("Send approval request upload");
    setIsLoading(false);
    handleReset();
  };

  const handleDocumentPropertyChange = (prop) => (event) => {
    setDocumentProperties({
      ...documentProperties,
      [prop]: event.target.value,
    });
  };

  const handleFileChange = async (target) => {
    if (target.files && target.files.length !== 0) {
      const file = target.files[0];
      const validExtensions = ["pdf", "jpeg", "jpg", "png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const mimeType = file.type;

      if (
        validExtensions.includes(fileExtension) &&
        (mimeType.includes("application/pdf") ||
          mimeType.includes("image/jpeg") ||
          mimeType.includes("image/jpg") ||
          mimeType.includes("image/png"))
      ) {
        const fileByteArray = await fileToUint8Array(file);
        const blobURL = URL.createObjectURL(file);
        setSource(fileByteArray);
        setBlobURL(blobURL);
        setFileName(file.name);
        setFileType(file.type);
        setDocumentProperties((prevProps) => ({
          ...prevProps,
          nomeFile: file.name,
          formatoFile: file.type,
          nomeFileDestinazione: file.name,
          impronta: toHex(calculateSHA256(fileByteArray)),
          algoritmo: "SHA-256",
        }));
      } else {
        console.error("Invalid file format");
        setIsInvalidFile(true);
        resetFileInput();
      }
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("new-upload-home-button");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleReset = () => {
    setSource("");
    setBlobURL("");
    URL.revokeObjectURL(blobURL);
    setFileName("");
    setFileType("");
    setDownloadUrl("");
    setDocumentProperties(initialDocumentProperties);
    setSelectedTag("");
    setIsInvalidFile(false);

    if (request) {
      setRequest();
    }

    setOpen(false);
    resetFileInput();
  };

  const handleResetUploadReceipt = () => {
    setUploadReceiptTXID(null);
    setUploadReceipt(null);
    setReceiptDialogOpen(false);
  };

  const handleChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleOpen = () => {
    setTagSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleClose = () => {
    setTagSelectionOpen(false);
  };

  const handleFileClick = (file) => {
    console.log(file);
    setSelectedFile(file);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleReset}
        onOpen={() => setOpen(true)}
        sx={{ "& .MuiDrawer-paper": { width: "100%", height: "90%" } }}
        PaperProps={{
          sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
        }}
      >
        <Puller />
        <Box sx={{ p: "5%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            mt="5%"
            mb="10%"
          >
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                New Upload
              </Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              <CDNFileForm
                isMobile={isMobile}
                request={request}
                tags={tags}
                location={location}
                source={source}
                downloadURL={downloadURL}
                blobURL={blobURL}
                fileName={fileName}
                fileType={fileType}
                documentProperties={documentProperties}
                tagSelectionOpen={tagSelectionOpen}
                selectedTag={selectedTag}
                handleDocumentPropertyChange={handleDocumentPropertyChange}
                handleFileChange={handleFileChange}
                handleChange={handleChange}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleFileClick={handleFileClick}
              />
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading || !source || !fileName || !fileType}
                    onClick={() => setConfirm(true)}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </SwipeableDrawer>
      <NewCertConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        handleGenerate={handleGenerate}
      />
      <NewRequestConfirm
        approval={approval}
        setApproval={setApproval}
        handleSendApprovalRequest={handleSendApprovalRequest}
      />
      <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} />
      {uploadReceipt && (
        <CDNFileUploadReceipt
          receiptDialogOpen={receiptDialogOpen}
          handleResetUploadReceipt={handleResetUploadReceipt}
          uploadReceipt={uploadReceipt}
        />
      )}
      {selectedFile && (
        <Dialog
          open={Boolean(selectedFile)}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="lg"
        >
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document
            file={selectedFile}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width={isMobile ? "300" : "600"}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === numPages}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}
    </>
  ) : (
    <>
      <Dialog open={open} onClose={handleReset} fullWidth maxWidth="lg">
        <Box sx={{ p: "2%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            mb="3%"
          >
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                New Upload
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleReset}
                sx={{ color: "red" }}
                edge="end"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              <CDNFileForm
                isMobile={isMobile}
                request={request}
                tags={tags}
                location={location}
                source={source}
                downloadURL={downloadURL}
                blobURL={blobURL}
                fileName={fileName}
                fileType={fileType}
                documentProperties={documentProperties}
                tagSelectionOpen={tagSelectionOpen}
                selectedTag={selectedTag}
                handleDocumentPropertyChange={handleDocumentPropertyChange}
                handleFileChange={handleFileChange}
                handleChange={handleChange}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleFileClick={handleFileClick}
              />
              <Grid item container xs={12} spacing={1} mt="3%">
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading || !source || !fileName || !fileType}
                    onClick={() => setConfirm(true)}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Dialog>
      <NewCertConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        handleGenerate={handleGenerate}
      />
      <NewRequestConfirm
        approval={approval}
        setApproval={setApproval}
        handleSendApprovalRequest={handleSendApprovalRequest}
      />
      <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} />
      {uploadReceipt && (
        <CDNFileUploadReceipt
          receiptDialogOpen={receiptDialogOpen}
          handleResetUploadReceipt={handleResetUploadReceipt}
          uploadReceipt={uploadReceipt}
        />
      )}
      {selectedFile && (
        <Dialog
          open={Boolean(selectedFile)}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="lg"
        >
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document
            file={selectedFile}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <Page
              pageNumber={currentPage}
              width={isMobile ? "300" : "600"}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === numPages}
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default CDNFileGenerator;
