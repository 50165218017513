// React
import React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';

// A ---------------------------------------------------------------------- M

// Install PWA
export const InstallBanner = (props) => {
    return (
        <Snackbar
            open={props.showInstallBanner}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            message="Install the PWA to get notifications"
            action={[
                <Button key="action1" onClick={props.handleCloseInstallBanner}>
                    Dismiss
                </Button>,
                <Button key="action2" onClick={props.handleClick}>
                    Install
                </Button>,
            ]}
            sx={{ mt: { xs: '55px', md: '5%' } }}
        />
    );
};

// Open with PWA
export const OpenWithBanner = (props) => {
    return (
        <Snackbar
            open={props.showOpenBanner}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            message="Open with app?"
            action={[
                <Button key="action1" onClick={props.handleCloseOpenBanner}>
                    Dismiss
                </Button>,
                <Link key="action2" target='_blank' to='/signin'>
                    <Button>
                        Open
                    </Button>
                </Link>
            ]}
            sx={{ mt: { xs: '55px', md: '5%' } }}
        />
    );
};

// Update PWA
export const UpdateBanner = (props) => {
    return (
        <Snackbar
            open={props.showUpdateBanner}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            message="A new version of this app is available."
            action={[
                <Button key="action1" onClick={props.reloadPage}>
                    Refresh
                </Button>
            ]}
            sx={{ mt: { xs: '55px', md: '5%' } }}
        />
    );
};