// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// reactflow
import ReactFlow, { MiniMap, Controls } from "reactflow";
import "reactflow/dist/style.css";

// i18n
import { useTranslation } from "react-i18next";

// Context
import { UserAuth } from "context/AuthContext";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Components
import TagToolbar from "ui-components/TagToolbar";

// Flow Monitor Components
import AuthorizedUsersNode from "flow_monitor_interface/AuthorizedUsersNode";
import AuthorizedKeysNode from "flow_monitor_interface/AuthorizedKeysNode";
import Databox from "flow_monitor_interface/Databox";
import Targets from "flow_monitor_interface/Targets";

// Functions
import getSeenTag from "user/getSeenTag";
import getTag from "tag/getTag";
import getAuthorizedUsersData from "flow_monitor_core/users/getAuthorizedUsersData";
import getAuthorizedKeysData from "flow_monitor_core/keys/getAuthorizedKeysData";
import getTargetsData from "flow_monitor_core/targets/getTargetsData";

// A ---------------------------------------------------------------------- M

const nodeTypes = {
  authorizedUsers: AuthorizedUsersNode,
  authorizedKeys: AuthorizedKeysNode,
  Databox: Databox,
  Targets: Targets,
};

const FlowMonitor = () => {
  const { user } = UserAuth();
  const { tag } = useParams();
  const { t } = useTranslation();

  const [seentag, setSeenTag] = useState();
  const [databoxName, setDataboxName] = useState();
  const [authorizedUsersData, setAuthorizedUsersData] = useState();
  const [authorizedKeysData, setAuthorizedKeysData] = useState();
  const [targetsData, setTargetsData] = useState();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSeenTag = async () => {
      const seentag = await getSeenTag(user.uid, tag);
      const tagData = await getTag(tag);
      const tagName = tagData.name;
      setSeenTag({ ...seentag, name: tagName });
    };

    const fetchData = async () => {
      // Get databox
      const databox = await getTag(tag);
      const databoxName = databox.name ? databox.name : databox.id;
      setDataboxName(databoxName);

      // Get authorized users data
      const authorizedUsersData = await getAuthorizedUsersData(tag);
      setAuthorizedUsersData(authorizedUsersData);

      const { authorizedUsersArray, totalNumberOfCertifications } =
        authorizedUsersData;

      // Get authorized keys data
      const authorizedKeysData = await getAuthorizedKeysData(tag);
      setAuthorizedKeysData(authorizedKeysData);

      const { authorizedKeysArray, totalNumberOfCertificationsKeys } =
        authorizedKeysData;

      // Get targets data
      const targetsData = await getTargetsData(tag);
      setTargetsData(targetsData);

      const { targetsArray, enabledTargetsCount } = targetsData;

      // Set nodes and edges for React Form
      setNodes([
        {
          id: "1",
          type: "authorizedUsers",
          data: { authorizedUsersArray, totalNumberOfCertifications },
          position: { x: 10, y: 500 },
          style: { width: 600 },
        },
        {
          id: "2",
          type: "authorizedKeys",
          data: { authorizedKeysArray, totalNumberOfCertificationsKeys },
          position: { x: 10, y: 1200 },
          style: { width: 600 },
        },
        {
          id: "3",
          type: "Databox",
          data: { databoxName },
          position: { x: 800, y: 950 },
          style: { backgroundColor: "#6865A5", color: "white" },
          targetPosition: "left",
          style: { width: 300 },
        },
        {
          id: "4",
          type: "Targets",
          data: { targetsArray, enabledTargetsCount },
          position: { x: 1300, y: 800 },
          style: { width: 800 },
        },
      ]);

      setEdges([
        {
          id: "e1-3",
          source: "1",
          target: "3",
          sourceHandle: "a",
          targetHandle: "c",
          animated: totalNumberOfCertifications > 0,
          type: "step",
          style: {
            stroke: totalNumberOfCertifications > 0 ? "#00c853" : "#b0b8b0",
          },
        },
        {
          id: "e2-3",
          source: "2",
          target: "3",
          sourceHandle: "b",
          targetHandle: "d",
          animated: totalNumberOfCertificationsKeys > 0,
          type: "step",
          style: {
            stroke: totalNumberOfCertificationsKeys > 0 ? "#00c853" : "#b0b8b0",
          },
        },
        {
          id: "e3-4",
          source: "3",
          target: "4",
          animated: enabledTargetsCount > 0,
          type: "step",
          style: { stroke: enabledTargetsCount > 0 ? "#00c853" : "#b60b8b0" },
        },
      ]);
    };

    const fetch = async () => {
      await fetchSeenTag();
      await fetchData();
      setIsLoading(false);
    };

    fetch();
  }, [tag]);

  return (
    <>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item container xs={12} spacing={5}>
          {seentag && (
            <Grid item xs={12}>
              <TagToolbar tag={seentag} />
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
          </Grid>

          <Grid item container xs={12} spacing={5}>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                {t('monitor')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <div style={{ width: "85vw", height: "40vh" }}>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  fitView
                  nodeTypes={nodeTypes}
                >
                  <MiniMap nodeStrokeWidth={3} zoomable pannable />
                  <Controls />
                </ReactFlow>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FlowMonitor;
