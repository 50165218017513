// Functions
import getForwardRoutes from "tag/getForwardRoutes";

// A ---------------------------------------------------------------------- M

const getTargetsData = async (databoxID) => {
    try {
        const targetsArray = await getForwardRoutes(databoxID);

        // Filter targets with enabled: true and count them
        const enabledTargetsCount = targetsArray.filter(target => target.enabled).length;

        console.log('targetsArray:', targetsArray);
        console.log('enabledTargetsCount:', enabledTargetsCount);

        return {
            targetsArray,
            enabledTargetsCount,
        }
    } catch (e) {
        console.error("Error in getTargets:", e.message);
        return {
            targetsArray: [],
            enabledTargetsCount: 0,
        };
    }
};

export default getTargetsData;