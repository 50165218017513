// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Context
import { UserAuth } from "context/AuthContext";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Icons
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import AddIcon from "@mui/icons-material/Add";

// Functions
import convertTimestamp from "utils/convertTimestamp";
import disableForwardRoute from "tag/disableForwardRoute";
import enableForwardRoute from "tag/enableForwardRoute";
import getTagOwner from "tag/getTagOwner";

// A ---------------------------------------------------------------------- M

const ForwardRoutesTableHead = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const headCells = [
    { id: "endpoint", label: t("endpoint"), mobileLabel: t("endpoint") },
    {
      id: "timestamp",
      label: t("creation_datetime"),
      mobileLabel: t("creation_datetime"),
    },
    { id: "type", label: t("type"), mobileLabel: t("type") },
    { id: "types", label: t("datatypes"), mobileLabel: t("datatypes") },
    { id: "status", label: t("status"), mobileLabel: t("status") },
    { id: "actions", label: t("actions"), mobileLabel: t("actions") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            (!isMobile ||
              headCell.id === "endpoint" ||
              headCell.id === "status" ||
              headCell.id === "actions") && (
              <TableCell
                key={headCell.id}
                align={headCell.id !== "actions" ? "left" : "right"}
              >
                {headCell.label}
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
};

const ForwardRoutesTableToolbar = ({ openAddForwardRouteDrawer, tagOwner }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="h6">{t("target_endpoints")}</Typography>
      </Grid>
      {tagOwner === user.uid && (
        <Grid item container xs={12} sm={12} md={6} justifyContent="flex-end">
          <Grid item xs={12} sm={12} md={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={openAddForwardRouteDrawer}
              startIcon={<AddIcon />}
            >
              {t("add_endpoint")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageOutlinedIcon />
        ) : (
          <FirstPageOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageOutlinedIcon />
        ) : (
          <LastPageOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ForwardRoutesTable = ({
  tag,
  forwardRoutes,
  openAddForwardRouteDrawer,
  clickEdit,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tagOwner, setTagOwner] = useState();

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - forwardRoutes.length) : 0;

  useEffect(() => {
    const fetchTagOwner = async () => {
      const tagOwner = await getTagOwner(tag.id);
      setTagOwner(tagOwner);
    };

    fetchTagOwner();
  }, [tag]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFirstPageButtonClick = () => {
    setPage(0);
  };

  const handleBackButtonClick = () => {
    setPage(page - 1);
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
  };

  const handleLastPageButtonClick = () => {
    setPage(Math.max(0, Math.ceil(forwardRoutes.length / rowsPerPage) - 1));
  };

  return (
    <Box>
      <ForwardRoutesTableToolbar
        openAddForwardRouteDrawer={openAddForwardRouteDrawer}
        tagOwner={tagOwner}
      />
      <TableContainer>
        <Table>
          <ForwardRoutesTableHead />
          <TableBody>
            {(rowsPerPage > 0
              ? forwardRoutes.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : forwardRoutes
            ).map((forwardRoute) => {
              return (
                <TableRow
                  key={forwardRoute.id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                >
                  {isMobile ? (
                    <TableCell
                      align="left"
                      sx={{
                        minWidth: "130px",
                        maxWidth: "130px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {forwardRoute.endpoint || "N/A"}
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      {forwardRoute.endpoint || "N/A"}
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell align="left">
                      {forwardRoute.added_on
                        ? convertTimestamp(forwardRoute.added_on)
                        : "N/A"}
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell align="left">
                      {forwardRoute.type || "N/A"}
                    </TableCell>
                  )}
                  {!isMobile && (
                    <TableCell align="left">
                      <List dense>
                        {forwardRoute.types.map((type) => (
                          <ListItem key={type}>
                            <ListItemText primary={type} />
                          </ListItem>
                        ))}
                      </List>
                    </TableCell>
                  )}
                  <TableCell
                    sx={{ color: forwardRoute.enabled ? "#00c853" : "#b0b8b0" }}
                    align="left"
                  >
                    {forwardRoute.enabled ? t("enabled").toUpperCase() : t("disabled").toUpperCase()}
                  </TableCell>
                  <TableCell align="right" size="small">
                    <Button
                      onClick={() => clickEdit(forwardRoute)}
                      size="small"
                    >
                      {t("edit")}
                    </Button>
                    <Button
                      onClick={() =>
                        forwardRoute.enabled
                          ? disableForwardRoute(tag.id, forwardRoute.id)
                          : enableForwardRoute(tag.id, forwardRoute.id)
                      }
                      size="small"
                    >
                      {forwardRoute.enabled ? t("disable") : t("enable")}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={isMobile ? 3 : 6} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={forwardRoutes.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
            >
              <FirstPageOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={
                page >= Math.ceil(forwardRoutes.length / rowsPerPage) - 1
              }
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={
                page >= Math.ceil(forwardRoutes.length / rowsPerPage) - 1
              }
            >
              <LastPageOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default ForwardRoutesTable;
