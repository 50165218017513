// React
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// react-camera-qr
import QrReader from 'react-camera-qr';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

//  A ---------------------------------------------------------------------- M

const QRCodeScanner = ({ open, setOpen }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleScan = (url) => {
        if (url) {
            const segments = url.split('/');
            const tag = segments[segments.length - 1];
            navigate(`/${tag}`);
        }
    };

    const handleError = (err) => {
        console.err(err);
    };

    useEffect(() => {
        const handlePopstate = () => {
            if (open) {
                setOpen(false);
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: '100%'
                }
            }}
        >
            <QrReader delay={500} onScan={handleScan} onError={handleError} />
            <Button variant="filled" onClick={() => setOpen(false)} fullWidth>{t('close')}</Button>
        </Dialog>
    );
};

export default QRCodeScanner;