// React
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';

// Functions
import getTagInfo from 'tag/getTagInfo';
import getGroup from 'group/getGroup';

// A ---------------------------------------------------------------------- M

// Custom styles
const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    breadcrumb: {
        marginBottom: theme.spacing(1.5),
    },
}));

const ORBreadcrumbs = () => {
    const classes = useStyles();
    const location = useLocation();
    const { conservSostL1 } = UserAuth();
    const { t } = useTranslation();

    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const staticRoutes = [
        '/my-account',
        '/signin',
        '/reset-password',
        '/new-user',
        '/system-consumption',
        '/my-certifications',
        '/my-groups',
        '/keypair-generator',
        '/reseller-panel'
    ];

    useEffect(() => {
        const fetchBreadcrumbs = async () => {
            const pathnames = location.pathname.split('/').filter((x) => x);
            const breadcrumbs = [];
            let tagName = '';

            if (pathnames.length > 0) {
                const firstSegment = `/${pathnames[0]}`;
                // Exclude static routes from breadcrumbs
                if (!staticRoutes.includes(firstSegment)) {
                    const firstSegment = pathnames[0];

                    // Determine if the second segment indicates a group route
                    const isGroupRoute = pathnames.length > 1 && ['databoxes', 'members'].includes(pathnames[1]);

                    if (isGroupRoute) {
                        breadcrumbs.push({ to: '/my-groups', title: t('my_groups') });
                        // Fetch group info
                        const groupInfo = await getGroup(firstSegment);
                        const groupName = groupInfo.name || 'N/A';

                        // Handle group-specific routes
                        if (pathnames[1] === 'databoxes') {
                            breadcrumbs.push({ to: `/${pathnames.join('/')}`, title: `${groupName} ${conservSostL1 ? t('archives') : t('databoxes')}` });
                        } else if (pathnames[1] === 'members') {
                            breadcrumbs.push({ to: `/${pathnames.join('/')}`, title: `${groupName}` });
                        }
                    } else {
                        breadcrumbs.push({ to: '/', title: conservSostL1 ? t('my_archives') : t('my_databoxes') });
                        // Fetch tag info
                        const tagInfo = await getTagInfo(firstSegment);
                        tagName = tagInfo.name || 'N/A';
                        breadcrumbs.push({ to: `/${firstSegment}`, title: tagName });

                        // Handle tag-specific routes
                        if (pathnames.length > 1 && pathnames[1] === 'settings') {
                            breadcrumbs.push({ to: `/${pathnames.join('/')}`, title: 'Settings' });
                        } else if (pathnames.length > 1 && pathnames[1] === 'monitor') {
                            breadcrumbs.push({ to: `/${pathnames.join('/')}`, title: 'Monitor' });
                        }
                    }
                } else if (firstSegment === '/my-groups') {
                    // breadcrumbs.push({ to: '/my-groups', title: 'My Groups' });
                }
            } else {
                // breadcrumbs.push({ to: '/', title: conservSostL1 ? 'My Archives' : 'My Databoxes' });
            }

            setBreadcrumbs(breadcrumbs);
        };

        fetchBreadcrumbs();
    }, [location]);

    return breadcrumbs.length > 0 ?
        <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            className={classes.breadcrumb}
            sx={{ mb: '1.5%' }}
        >
            {breadcrumbs.map((breadcrumb) => (
                <Link key={breadcrumb.to} to={breadcrumb.to} className={classes.link}>
                    {breadcrumb.title}
                </Link>
            ))}
        </Breadcrumbs> : null;
};

export default ORBreadcrumbs;