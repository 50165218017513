// React
import React, { useState } from "react";

import PropTypes from "prop-types";

// Material UI
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const headCells = [
  {
    id: "timestamp",
    label: "Certification",
  },
  {
    id: "creator",
    label: "Creation",
  },
  {
    id: " target",
    label: "Target",
  },
  {
    id: " id",
    label: "ID",
  },
  {
    id: "actions",
    label: "",
  },
];

const ConfigTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ConfigTableToolbar = () => {
  return (
    <Grid container p="1%">
      <Grid item md={4}>
        <Typography variant="h6" color="gray">
          Configurations History
        </Typography>
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = (props) => {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const RowWithAccordion = ({
  record,
  checkVerification,
  isLastRecentRecord,
}) => {
  const [expanded, setExpanded] = useState(isLastRecentRecord);

  const jsonConfig = JSON.parse(record.data.jsonconfig);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow key={record.id}>
        <TableCell align="left">
          {convertTimestamp(record.data.timestamp)}
        </TableCell>
        <TableCell align="left">{record.creator_email}</TableCell>
        <TableCell align="left">{record.data.target}</TableCell>
        <TableCell align="left">
          {record.txid ? record.txid.slice(0, 6) : "7af3ab"}
        </TableCell>
        <TableCell align="right">
          <Button onClick={() => checkVerification(record)}>Verify</Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Accordion expanded={expanded} onChange={toggleExpand}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="gray">Configuration Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {Object.entries(jsonConfig).map(([key, value]) => (
                  <div key={key}>
                    <Typography variant="body2">
                      <b>{key}</b>: {value}
                    </Typography>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function HistoryConfig({ records, checkVerification }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Paper elevation={4}>
        <ConfigTableToolbar />
        <TableContainer>
          <Table>
            <ConfigTableHead />
            <TableBody>
              {(rowsPerPage > 0
                ? records.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : records
              ).map((record, index) => {
                const isLastRecentRecord = index === 0 && page === 0;
                return (
                  <RowWithAccordion
                    key={`${record.id}-${isLastRecentRecord}`}
                    record={record}
                    checkVerification={checkVerification}
                    isLastRecentRecord={isLastRecentRecord}
                  />
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={records.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
