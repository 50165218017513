// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// react-pdf
import { Document, Page, pdfjs } from 'react-pdf';

// Material UI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

// Components
import CertificationMenu from 'ui-components/CertificationMenu';

// Icons
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Functions
import convertTimestamp from 'utils/convertTimestamp';

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CertificationsTableHead = () => {
    const { conservSostL1 } = UserAuth();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const headCells = [
        { id: 'databox', label: conservSostL1 ? t('assigned_archive') : t('assigned_databox'), mobileLabel: conservSostL1 ? t('assigned_archive') : t('assigned_databox') },
        { id: 'timestamp', label: conservSostL1 ? t('upload_datetime') : t('creation_datetime'), mobileLabel: conservSostL1 ? t('upload_datetime') : t('creation_datetime') },
        { id: 'type', label: t('type'), mobileLabel: t('type') },
        { id: 'actions', label: t('actions'), mobileLabel: t('actions') }
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    (!isMobile || headCell.id === 'databox' || headCell.id === 'timestamp' || headCell.id === 'type' || headCell.id === 'actions') && (
                        <TableCell key={headCell.id} align={headCell.id !== 'actions' ? 'left' : 'right'}>
                            {isMobile ? headCell.mobileLabel : headCell.label}
                        </TableCell>
                    )
                ))}
            </TableRow>
        </TableHead>
    );
};

const CertificationsTableToolbar = ({ openDocGenDrawer, openImageGenDrawer, openInfoGenDrawer, openUploadGenDrawer }) => {
    const { canSign, seedflow, APIKey, conservSostL1 } = UserAuth();
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={10}>
                <Typography variant="h5">
                    <b>{conservSostL1 ? t('my_documents') : t('my_certifications')}</b>
                </Typography>
            </Grid>
            {!conservSostL1 &&
                <Grid item container xs={12} sm={12} md={2} spacing={1} justifyContent='flex-end'>
                    <Grid item xs={12} sm={12} md={12}>
                        {((canSign && seedflow) || (conservSostL1 && APIKey)) &&
                            <Grid item xs={12} md={12}>
                                <CertificationMenu
                                    openDocGenDrawer={openDocGenDrawer}
                                    openImageGenDrawer={openImageGenDrawer}
                                    openInfoGenDrawer={openInfoGenDrawer}
                                    openUploadGenDrawer={openUploadGenDrawer}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

const TablePaginationActions = (props) => {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
            </IconButton>
        </Box>
    );
};

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const CertificationsTable = ({ signatures, clickView, openDocGenDrawer, openImageGenDrawer, openInfoGenDrawer, openUploadGenDrawer }) => {
    console.log(signatures);

    const { conservSostL1 } = UserAuth();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selectedFile, setSelectedFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - signatures.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFirstPageButtonClick = () => {
        setPage(0);
    };

    const handleBackButtonClick = () => {
        setPage(page - 1);
    };

    const handleNextButtonClick = () => {
        setPage(page + 1);
    };

    const handleLastPageButtonClick = () => {
        setPage(Math.max(0, Math.ceil(signatures.length / rowsPerPage) - 1));
    };

    const handleCloseDialog = () => {
        setSelectedFile(null);
        setCurrentPage(1);
    };

    const handleDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleCloseDialogImage = () => {
        setSelectedImage(null);
    };

    return (
        <Box>
            <CertificationsTableToolbar
                openDocGenDrawer={openDocGenDrawer}
                openImageGenDrawer={openImageGenDrawer}
                openInfoGenDrawer={openInfoGenDrawer}
                openUploadGenDrawer={openUploadGenDrawer}
            />
            <br />
            <TableContainer>
                <Table>
                    <CertificationsTableHead />
                    <TableBody>
                        {(rowsPerPage > 0
                            ? signatures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : signatures
                        ).map((signature) => {
                            let type;

                            if (signature.type === 'img') {
                                type = t('image');
                            } else if (signature.type === 'doc') {
                                type = t('document');
                            } else if (signature.type === 'info') {
                                type = t('note');
                            } else if (signature.type === 'CDNFile') {
                                type = t('file')
                            } else {
                                type = t('custom');
                            }

                            return (
                                <TableRow key={signature.txid} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                                    <TableCell
                                        align='left'
                                        sx={{
                                            color: signature.name ? 'black' : 'gray',
                                            minWidth: '90px',
                                            maxWidth: '90px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {signature.name || "No databox"}
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{
                                            minWidth: '90px',
                                            maxWidth: '90px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {signature.timestamp ? convertTimestamp(signature.timestamp) : 'N/A'}
                                    </TableCell>
                                    <TableCell
                                        align='left'
                                        sx={{
                                            minWidth: '40px',
                                            maxWidth: '40px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {type}
                                    </TableCell>
                                    <TableCell
                                        align='right'
                                        size='small'
                                    >
                                        <Button onClick={() => clickView(signature)} size='small'>{conservSostL1 ? t('details') : t('verify')}</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={4} />
                            </TableRow>
                        )}
                    </TableBody>
                    {!isMobile &&
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
                                    colSpan={4}
                                    count={signatures.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
                {isMobile &&
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            onClick={handleFirstPageButtonClick}
                            disabled={page === 0}
                        >
                            <FirstPageOutlinedIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleBackButtonClick}
                            disabled={page === 0}
                        >
                            <KeyboardArrowLeftOutlinedIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleNextButtonClick}
                            disabled={page >= Math.ceil(signatures.length / rowsPerPage) - 1}
                        >
                            <KeyboardArrowRightOutlinedIcon />
                        </IconButton>
                        <IconButton
                            onClick={handleLastPageButtonClick}
                            disabled={page >= Math.ceil(signatures.length / rowsPerPage) - 1}
                        >
                            <LastPageOutlinedIcon />
                        </IconButton>
                    </Box>
                }
            </TableContainer>

            {selectedFile &&
                <Dialog
                    open={Boolean(selectedFile)}
                    onClose={handleCloseDialog}
                >

                    <IconButton
                        sx={{
                            color: 'red',
                            justifyContent: 'flex-end',
                        }}
                        onClick={handleCloseDialog}
                    >
                        <CloseOutlinedIcon />
                    </IconButton>
                    <Document
                        file={selectedFile}
                        onLoadSuccess={handleDocumentLoadSuccess}
                    >
                        <Page
                            pageNumber={currentPage}
                            width='550'
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                        />
                    </Document>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <KeyboardArrowLeftOutlinedIcon />
                        </IconButton>
                        <Typography sx={{ mx: 2 }}>{currentPage} / {numPages}</Typography>
                        <IconButton
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === numPages}
                        >
                            <KeyboardArrowRightOutlinedIcon />
                        </IconButton>
                    </Box>
                </Dialog>
            }

            <Dialog open={Boolean(selectedImage)} onClose={handleCloseDialogImage}>
                <>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '2%',
                            right: '2%',
                            zIndex: 1,
                            color: 'red',
                        }}
                        onClick={handleCloseDialogImage}
                    >
                        <CloseOutlinedIcon fontSize='small' />
                    </IconButton>
                    <img src={selectedImage} alt="img" style={{ width: '100%', height: '100%' }} />
                </>
            </Dialog>
        </Box>
    );
};

export default CertificationsTable;