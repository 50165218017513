// React
import React from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// A ---------------------------------------------------------------------- M

const AccountStatus = () => {
    const { APIKey } = UserAuth();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant='h6' p={isMobile ? '3%' : '0%'} fontWeight='bold'>
                {t('account_status')}
            </Typography>
            <Divider />
            <CardContent>
                {APIKey &&
                    <Typography variant={isMobile ? 'body2' : 'body1'} color='green' fontWeight='bold' gutterBottom>
                        {t('service_active')}
                    </Typography>
                }
            </CardContent>
        </Box>
    );
};

export default AccountStatus;