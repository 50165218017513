// React
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

// Icons
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import StreamIcon from '@mui/icons-material/Stream';

// Components
import TagQRCode from './TagQRCode';

// Firebase
import { db } from 'config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

// Functions
import checkIfUserIsFollowing from 'tag/checkIfUserIsFollowing';
import addFollower from 'tag/addFollower';
import removeFollower from 'tag/removeFollower';
import getTagOwner from 'tag/getTagOwner';
import getUserContacts from 'user/getUserContacts';
import convertTimestamp from 'utils/convertTimestamp';

// A ---------------------------------------------------------------------- M

const TagToolbar = ({ tag }) => {
	const { user, conservSostL1 } = UserAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [openQR, setOpenQR] = useState(false);
	const [isFollowing, setIsFollowing] = useState(false);
	const [tagOwner, setTagOwner] = useState();
	const [tagOwnerEmail, setTagOwnerEmail] = useState();

	useEffect(() => {
		const checkIsFollowing = async () => {
			const following = await checkIfUserIsFollowing(user.uid, tag.id);
			setIsFollowing(following);
		}

		checkIsFollowing();

		const unsubscribe = onSnapshot(doc(db, 'tagsdata', tag.id), (doc) => {
			const followers = doc.data().followers;
			if (followers && followers.includes(user.uid)) {
				checkIsFollowing();
			} else {
				checkIsFollowing();
			}
		});

		return () => {
			unsubscribe();
		};
	}, [user, tag]);

	useEffect(() => {
		const fetchTagOwner = async () => {
			const tagOwner = await getTagOwner(tag.id);
			const tagOwnerEmail = await getUserContacts(tagOwner);
			setTagOwner(tagOwner);
			setTagOwnerEmail(tagOwnerEmail.email);
		}

		fetchTagOwner();
	}, [tag]);

	const handleQRCode = () => {
		setOpenQR(true);
		window.history.pushState(null, '');
	};

	const handleFollowToggle = async () => {
		if (isFollowing) {
			await removeFollower(user.uid, tag.id);
			console.log('unfollowed');
		} else {
			await addFollower(user.uid, tag.id);
			console.log('followed');
		}

		setIsFollowing(!isFollowing);
	};

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper
						elevation={4}
						sx={{
							p: '2%',
							backgroundColor: '#f5f5f5',
							borderRadius: 8,
						}}
					>
						<Grid container spacing={3}>
							<Grid item container xs={12}>
								<Grid item xs={8}>
									<Typography variant='h4' fontWeight='bold'>
										<Link to={`/${tag.id}`} style={{ textDecoration: 'none' }}>
											{tag.name || 'N/A'}
										</Link>
									</Typography>
								</Grid>
								<Grid item container xs={4} justifyContent="flex-end">
									<Typography
										sx={{
											backgroundColor: 'gray',
											color: '#ffffff',
											borderRadius: 1,
											px: 2,
											py: 1,
											fontWeight: 'bold',
											fontSize: '1rem',
										}}
									>
										{conservSostL1 ? t('archive') : t('databox')}
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item container xs={12}>
								<Grid item xs={12}>
									<Typography gutterBottom color='gray' variant='h6'>
										{conservSostL1 ? t('archive_information') : t('databox_information')}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom>
										<b>{t('id')}</b>: {tag.id}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom>
										<b>{t('name')}</b>: {tag.name || 'N/A'}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom>
										<b>{t('owner')}</b>: {tagOwnerEmail || 'N/A'}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom>
										<b>{t('description')}</b>: {tag.notes || 'N/A'}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom>
										<b>{t('registration_datetime')}</b>: {tag.firstseen ? convertTimestamp(tag.firstseen) : 'N/A'}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item container xs={12} justifyContent='flex-end' spacing={1}>
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Button
							startIcon={<PlaylistAddCheckIcon />}
							variant='contained'
							fullWidth
							onClick={() => navigate(`/${tag.id}`)}
						>
							{conservSostL1 ? t('documents') : t('certifications')}
						</Button>
					</Grid>
					{tagOwner && tagOwner === user.uid &&
						<Grid item xs={12} sm={12} md={2} lg={2}>
							<Button
								startIcon={<SettingsIcon />}
								variant='contained'
								fullWidth
								onClick={() => navigate(`/${tag.id}/settings`)}
							>
								{t('settings')}
							</Button>
						</Grid>
					}
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Button
							startIcon={<StreamIcon />}
							variant='contained'
							fullWidth
							onClick={() => navigate(`/${tag.id}/monitor`)}
						>
							{t('monitor')}
						</Button>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Button
							startIcon={<QrCodeIcon />}
							variant='contained'
							fullWidth
							onClick={handleQRCode}
						>
							{t('qr_code')}
						</Button>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Button
							startIcon={isFollowing ? <NotificationsNoneIcon /> : <NotificationsActiveIcon />}
							variant='contained'
							fullWidth
							onClick={handleFollowToggle}
						>
							{isFollowing ? t('unfollow') : t('follow')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<TagQRCode tag={tag.id} open={openQR} setOpen={setOpenQR} />
		</>
	);
};

export default TagToolbar;