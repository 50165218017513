import sendCertificationStreamConfig from "./sendCertificationStreamConfig";
import sendDataStreamConfig from "./sendDataStreamConfig";
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genConfigRecord = async (uid, keypair, target, jsonconfig, tdr, isPublic = false) => {
    try {
        const timestamp = unixTimestampInSeconds();

        if (tdr) {
            // Certification stream
            const data = await sendCertificationStreamConfig(keypair, target, jsonconfig, timestamp, tdr);

            if (data.txid) {
                // Data stream
                const outcome = await sendDataStreamConfig(uid, target, jsonconfig, data, timestamp, tdr, isPublic);

                if (outcome.success) {
                    console.log(data);
                    return {
                        inserted: data.inserted,
                        txid: data.txid
                    };
                } else {
                    return {
                        error: outcome.error
                    };
                }
            } else {
                return {
                    error: data.error
                }
            }
        } else {
            // Certification stream
            const data = await sendCertificationStreamConfig(keypair, target, jsonconfig, timestamp);

            if (data.txid) {
                // Data stream
                const outcome = await sendDataStreamConfig(uid, target, jsonconfig, data, timestamp);

                if (outcome.success) {
                    console.log(data);
                    return {
                        inserted: data.inserted,
                        txid: data.txid
                    };
                } else {
                    return {
                        error: outcome.error
                    };
                }
            } else {
                return {
                    error: data.error
                };
            }
        }
    } catch (e) {
        console.error('Error in genConfigRecord:', e.message);

        return {
            error: e.message
        };
    }
};

export default genConfigRecord;