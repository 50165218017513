// React
import React from 'react';

// Material UI
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

// React Flow
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';

// A ---------------------------------------------------------------------- M

const Targets = ({ data }) => {
    return (
        <Paper sx={{ p: '1%' }}>
            <Typography variant='h6' align='center' gutterBottom>
                <b>Target Endpoints</b>
            </Typography>
            {/* Targets Metrics*/}
            <Typography variant='body1' align='center' gutterBottom>
                <b>Tot. Endpoints: {data.enabledTargetsCount}</b>
                <br />
                <b>Tot. Enabled Endpoints: {data.enabledTargetsCount}</b>
            </Typography>
            {/* Targets Data */}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><b>Name</b></TableCell>
                        <TableCell><b>Type</b></TableCell>
                        <TableCell><b>Status</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.targetsArray.map((target, index) => (
                        <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                            <TableCell>
                                {target.endpoint ? target.endpoint : 'N/A'}
                            </TableCell>
                            <TableCell>
                                {target.type ? target.type : 'REST'}
                            </TableCell>
                            <TableCell sx={{ color: target.enabled ? '#00c853' : '#b0b8b0' }}>
                                {target.enabled ? 'ENABLED' : 'DISABLED'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Handle type="target" position={Position.Left} id='e' />
        </Paper>
    );
};

export default Targets;