// React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

// Functions
import convertTimestamp from 'utils/convertTimestamp';

// A ---------------------------------------------------------------------- M

const DataBoxesTableHead = () => {
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();

	const headCells = [
		{ id: 'name', label: t('name'), mobileLabel: t('name') },
		{ id: 'timestamp', label: t('registration_datetime'), mobileLabel: t('registration_datetime') },
		{ id: 'actions', label: t('actions'), mobileLabel: t('actions') },
	];

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					(!isMobile || headCell.id === 'name' || headCell.id === 'timestamp' || headCell.id === 'actions') && (
						<TableCell key={headCell.id} align={headCell.id !== 'actions' ? 'left' : 'right'}>
							{isMobile ? headCell.mobileLabel : headCell.label}
						</TableCell>
					)
				))}
			</TableRow>
		</TableHead>
	);
};

const DataBoxesTableToolbar = ({ handleOpen, searchQuery, handleSearchChange }) => {
	const { canCreateDatabox, conservSostL1 } = UserAuth();
	const { t } = useTranslation();

	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={6}>
				<Typography variant="h5" fontWeight="bold">
					{conservSostL1 ? t('my_archives') : t('my_databoxes')}
				</Typography>
			</Grid>
			<Grid item container xs={12} sm={12} md={6} spacing={2} justifyContent="flex-end">
				<Grid item xs={12} sm={12} md={9}>
					<TextField
						fullWidth
						placeholder={t('filter_by_name')}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</Grid>
				{(canCreateDatabox || conservSostL1) &&
					<Grid item xs={12} sm={12} md={3}>
						<Button variant='contained' sx={{ height: '100%' }} fullWidth onClick={handleOpen} startIcon={<AddIcon />}>
							{conservSostL1 ? t('new_archive') : t('new_databox')}
						</Button>
					</Grid>
				}
			</Grid>
		</Grid>
	);
};

const TablePaginationActions = (props) => {
	const theme = useTheme();

	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5, }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
			</IconButton>
		</Box>
	);
};

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const DataBoxesTable = ({ tags, handleOpen }) => {
	console.log(tags);

	const navigate = useNavigate();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { t } = useTranslation();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [searchQuery, setSearchQuery] = useState('');
	const [filteredTags, setFilteredTags] = useState(tags);

	useEffect(() => {
		setFilteredTags(tags);
	}, [tags]);

	useEffect(() => {
		const filtered = tags.filter(tag => tag.nickname.toLowerCase().includes(searchQuery.toLowerCase()));
		setFilteredTags(filtered);
		setPage(0);
		setRowsPerPage(20);
	}, [searchQuery]);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredTags.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFirstPageButtonClick = () => {
		setPage(0);
	};

	const handleBackButtonClick = () => {
		setPage(page - 1);
	};

	const handleNextButtonClick = () => {
		setPage(page + 1);
	};

	const handleLastPageButtonClick = () => {
		setPage(Math.max(0, Math.ceil(filteredTags.length / rowsPerPage) - 1));
	};

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	return (
		<Box>
			<DataBoxesTableToolbar handleOpen={handleOpen} searchQuery={searchQuery} handleSearchChange={handleSearchChange} />
			<br />
			<TableContainer>
				<Table>
					<DataBoxesTableHead />
					<TableBody>
						{(rowsPerPage > 0
							? filteredTags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: filteredTags
						).map((tag) => {
							return <TableRow key={tag.id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
								<TableCell
									align='left'
									sx={{
										minWidth: '90px',
										maxWidth: '90px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{tag.name || "N/A"}
								</TableCell>
								<TableCell
									align='left'
									sx={{
										minWidth: '90px',
										maxWidth: '90px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{tag.firstseen ? convertTimestamp(tag.firstseen) : "N/A"}
								</TableCell>
								<TableCell
									align='right'
									size='small'
								>
									<Button onClick={() => navigate(`/${tag.id}`)} size='small'>{t('open')}</Button>
								</TableCell>
							</TableRow>
						}
						)}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={3} />
							</TableRow>
						)}
					</TableBody>
					{!isMobile &&
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[10, 20, { label: 'All', value: -1 }]}
									colSpan={3}
									count={filteredTags.length}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									ActionsComponent={TablePaginationActions}
								/>
							</TableRow>
						</TableFooter>
					}
				</Table>
				{isMobile &&
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<IconButton
							onClick={handleFirstPageButtonClick}
							disabled={page === 0}
						>
							<FirstPageOutlinedIcon />
						</IconButton>
						<IconButton
							onClick={handleBackButtonClick}
							disabled={page === 0}
						>
							<KeyboardArrowLeftOutlinedIcon />
						</IconButton>
						<IconButton
							onClick={handleNextButtonClick}
							disabled={page >= Math.ceil(filteredTags.length / rowsPerPage) - 1}
						>
							<KeyboardArrowRightOutlinedIcon />
						</IconButton>
						<IconButton
							onClick={handleLastPageButtonClick}
							disabled={page >= Math.ceil(filteredTags.length / rowsPerPage) - 1}
						>
							<LastPageOutlinedIcon />
						</IconButton>
					</Box>
				}
			</TableContainer>
		</Box>
	);
};

export default DataBoxesTable;