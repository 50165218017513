// React
import React from 'react';

// Context
import { UserAuth } from 'context/AuthContext';

// Material UI
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

// React Flow
import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';

// A ---------------------------------------------------------------------- M

const handleStyleC = { top: 20 };
const handleStyleD = { top: 70 };

const Databox = ({ data }) => {
    const { conservSostL1 } = UserAuth();

    return (
        <Paper sx={{ p: '1%' }}>
            <Typography variant='h6' align='center' gutterBottom fontWeight='bold'>
                {!conservSostL1 ? 'Databox' : 'Archive'}
            </Typography>
            {/* Databox Information */}
            <Typography variant='h6' align='center' gutterBottom>
                <b>{data.databoxName}</b>
            </Typography>
            <Handle type="target" position={Position.Left} style={handleStyleC} id='c' />
            <Handle type="target" position={Position.Left} style={handleStyleD} id='d' />
            <Handle type="source" position={Position.Right} id='e' />
        </Paper>
    );
};

export default Databox;