// Functions
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";
import sendDataStreamCDNFile from "./sendDataStreamCDNFile";

// SafeTwin
import { genRndStringAlphanumericLowercase } from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const genCDNFileRecord = async (uid, dataBody, databoxID) => {
    try {
        const timestamp = unixTimestampInSeconds();

        if (!databoxID) {
            console.error("Error in genCDNFileRecord: missing databoxID");
            return {
                success: false,
                error: "Error in genCDNFileRecord: missing databoxID"
            };
        }

        // Mock certification stream
        const dataCertification = {
            txid: genRndStringAlphanumericLowercase(64),
        }

        if (!dataCertification.txid) {
            console.error("Error in genCDNFileRecord: missing txid");
            return {
                success: false,
                error: "Error in genCDNFileRecord: missing txid"
            };
        }

        // Data stream
        const result = await sendDataStreamCDNFile(uid, dataBody, dataCertification, timestamp, databoxID);
        console.log(result);
        return result;
    } catch (e) {
        console.error("Error in genCDNFileRecord:", e.message);
        const result = {
            success: false,
            error: e.message
        };
        console.log(result);
        return result;
    }
};

export default genCDNFileRecord;