// React
import React, { useState, useEffect } from 'react';

// Material UI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

// A ---------------------------------------------------------------------- M

// LoadingComponent
export const LoadingComponent = (props) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    );
};

// LinearLoadingComponent
export const LinearLoadingComponent = (props) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 30;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ ...props.style }}>
            <Typography align='center' variant="body1" sx={{ mb: '1%' }}>
                Loading...
            </Typography>
            <LinearProgress variant="determinate" value={progress} />
        </Box>
    );
};

// LoadingDialog
export const LoadingDialog = ({ open }) => {
    return (
        <Dialog open={open} aria-labelledby="loading-dialog">
            <Box display="flex" justifyContent="center" alignItems="center" p={3}>
                <CircularProgress />
            </Box>
        </Dialog>
    );
};
