// React
import React, { useState, useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";

// Function
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const SensorDataAggregatedTableHead = ({ selectedType }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const headCellsData = [
    { id: "id", label: t("id"), mobileLabel: t("id") },
    {
      id: "timestamp",
      label: t("generation_datetime"),
      mobileLabel: t("generation_datetime"),
    },
    { id: "value", label: t("value"), mobileLabel: t("value") },
    {
      id: "measure_unit",
      label: t("measure_unit"),
      mobileLabel: t("measure_unit"),
    },
    { id: "magnitude", label: t("magnitude"), mobileLabel: t("magnitude") },
    { id: "actions", label: t("actions"), mobileLabel: t("actions") },
  ];

  const headCellsPredictions = [
    { id: "id", label: t("id"), mobileLabel: t("id") },
    {
      id: "timestamp",
      label: t("generation_datetime"),
      mobileLabel: t("generation_datetime"),
    },
    {
      id: "windowStart",
      label: t("window_start"),
      mobileLabel: t("window_start"),
    },
    { id: "windowEnd", label: t("window_end"), mobileLabel: t("window_end") },
    { id: "prediction", label: t("prediction"), mobileLabel: t("prediction") },
    { id: "alarm", label: t("alarm"), mobileLabel: t("alarm") },
    { id: "actions", label: t("actions"), mobileLabel: t("actions") },
  ];

  const headCells =
    selectedType === "predictions" ? headCellsPredictions : headCellsData;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            {isMobile ? headCell.mobileLabel : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const SensorDataAggregatedTable = ({
  records,
  aiDataOutputRecords,
  selectedSensors,
  checkVerification,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState("");
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (selectedSensor) {
        if (selectedType === "data") {
          const filteredRecords = records.reduce((acc, record) => {
            if (record.sensorId === selectedSensor) {
              record.magnitudes.forEach((mag) => acc.push(...mag.records));
            }
            return acc;
          }, []);
          setFilteredRecords(filteredRecords.reverse());
        } else if (selectedType === "predictions") {
          const predictions = aiDataOutputRecords[selectedSensor] || [];
          setFilteredRecords(predictions.reverse());
        } else {
          setFilteredRecords([]);
        }
      } else {
        setFilteredRecords([]);
      }
    };

    fetchData();
  }, [selectedSensor, selectedType, records, aiDataOutputRecords]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSensor = (event) => {
    setSelectedSensor(event.target.value);
  };

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>
          <Select
            fullWidth
            value={selectedSensor}
            onChange={handleChangeSensor}
            displayEmpty
            inputProps={{ "aria-label": "Select Sensor" }}
          >
            <MenuItem value="" disabled>
              {t("select_sensor")}
            </MenuItem>
            {selectedSensors.map((sensorId) => (
              <MenuItem key={sensorId} value={sensorId}>
                {sensorId}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Select
            fullWidth
            value={selectedType}
            onChange={handleChangeType}
            displayEmpty
            inputProps={{ "aria-label": "Select Type" }}
          >
            <MenuItem value="" disabled>
              {t("select_type")}
            </MenuItem>
            <MenuItem value="data">{t("show_data")}</MenuItem>
            <MenuItem value="predictions">{t("show_predictions")}</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <SensorDataAggregatedTableHead selectedType={selectedType} />
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRecords.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRecords
            ).map((record) => {
              if (selectedType === "data") {
                return (
                  <TableRow
                    key={record.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    {isMobile ? (
                      <TableCell align="left">
                        {record.id.substring(0, 6)}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{record.id}</TableCell>
                    )}
                    <TableCell align="left">
                      {convertTimestamp(record.data.timestamp_end) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.avg || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.measure_unit || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.magnitude || "N/A"}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Button onClick={() => checkVerification(record)}>
                        {t("verify")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              } else if (selectedType === "predictions") {
                return (
                  <TableRow
                    key={record.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    {isMobile ? (
                      <TableCell align="left">
                        {record.id.substring(0, 6)}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{record.id}</TableCell>
                    )}
                    <TableCell align="left">
                      {convertTimestamp(record.data.timestamp) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {convertTimestamp(record.data.windowStart) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {convertTimestamp(record.data.windowEnd) || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.prediction || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.alarm || "N/A"}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Button onClick={() => checkVerification(record)}>
                        Verify
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRecords.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default SensorDataAggregatedTable;
