// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

// Functions
import convertTimestamp from 'utils/convertTimestamp';

// A ---------------------------------------------------------------------- M

const CDNFileDetailsDialog = ({ open, onClose, record }) => {
    const fields = [
        { label: 'Upload Datetime', value: convertTimestamp(record.timestamp) },
        { label: 'Nome File', value: record.fileName },
        { label: 'Formato File', value: record.fileType },
        { label: 'Nome File Di Destinazione', value: record.nomeFileDestinazione },
        { label: 'Cartella Di Destinazione', value: record.cartellaDestinazione },
        { label: 'Classe Documentale', value: record.classeDocumentale },
        { label: 'Data Documento', value: record.dataDocumento },
        { label: 'Impronta', value: record.impronta },
        { label: 'Algoritmo', value: record.algoritmo },
        { label: 'Identificativo Documento', value: record.identificativoDocumento },
        { label: 'Versione Del Documento', value: record.versioneDocumento },
        { label: 'Modalità Di Formazione', value: record.modalitaFormazione },
        { label: 'Tipologia Di Flusso', value: record.tipologiaFlusso },
        { label: 'Tipo Registro', value: record.tipoRegistro },
        { label: 'Data Registrazione', value: record.dataRegistrazione },
        { label: 'Numero Documento', value: record.numeroDocumento },
        { label: 'Codice Registro', value: record.codiceRegistro },
        { label: 'Oggetto', value: record.oggetto },
        { label: 'Ruolo', value: record.ruolo },
        { label: 'Tipo Soggetto', value: record.tipoSoggetto },
        { label: 'Cognome', value: record.cognome },
        { label: 'Nome', value: record.nome },
        { label: 'Denominazione', value: record.denominazione },
        { label: 'Codice Fiscale', value: record.codiceFiscale },
        { label: 'Indirizzi Digitali Di Riferimento', value: record.indirizziDigitaliDiRiferimento },
        { label: 'Allegati Numero', value: record.allegatiNumero },
        { label: 'IdDoc Indice Allegati', value: record.IdDocIndiceAllegati },
        { label: 'Descrizione Allegati', value: record.descrizioneAllegati },
        { label: 'Indice Di Classificazione', value: record.indiceDiClassificazione },
        { label: 'Descrizione Classificazione', value: record.descrizioneClassificazione },
        { label: 'Riservato', value: record.riservato },
        { label: 'Piano Classificazione', value: record.pianoClassificazione },
        { label: 'Prodotto Software Nome', value: record.prodottoSoftwareNome },
        { label: 'Prodotto Software Versione', value: record.prodottoSoftwareVersione },
        { label: 'Prodotto Software Produttore', value: record.prodottoSoftwareProduttore },
        { label: 'Verifica Firma Digitale', value: record.verificaFirmaDigitale },
        { label: 'Verifica Marca Temporale', value: record.verificaMarcaTemporale },
        { label: 'Verifica Sigillo', value: record.verificaSigillo },
        { label: 'Verifica Conformità Copie', value: record.verificaConformitaCopie },
        { label: 'Id Aggregazione', value: record.IdAggregazione },
        { label: 'Identificativo Documento Principale', value: record.IdentificativoDocumentoPrincipale },
        { label: 'Tracciatura Modifiche - Tipo', value: record.tracciaturaModificheTipo },
        { label: 'Soggetto Autore Della Modifica', value: record.soggettoAutoreModifica },
        { label: 'Tracciatura Modifiche - Data', value: record.tracciaturaModificheData },
        { label: 'Tempo Di Conservazione', value: record.tempoConservazione },
        { label: 'Tracciatura Modifiche - IdDoc Versione Precedente', value: record.tracciaturaModificheIdDocVersionePrecedente },
        { label: 'Note', value: record.note },
    ];

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <Grid container p='2%' spacing={3}>
                <Grid item container alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='h5' fontWeight='bold'>Upload Details</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose} sx={{ color: 'red' }} edge='end'>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                label="Upload ID"
                                value={record.id}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                sx={{ backgroundColor: '#f5f5f5' }}
                            />
                        </Grid>
                        {fields.map((field, index) =>
                            field.value ? (
                                <Grid item xs={12} sm={4} key={index}>
                                    <TextField
                                        label={field.label}
                                        value={field.value}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        sx={{ backgroundColor: '#f5f5f5' }}
                                    />
                                </Grid>
                            ) : null
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

CDNFileDetailsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired
};

export default CDNFileDetailsDialog;