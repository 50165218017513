// React
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Context
import { UserAuth } from 'context/AuthContext';

// i18n
import { useTranslation } from 'react-i18next';

// Material UI
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

// Icons
import FolderIcon from '@mui/icons-material/Folder';
import GroupsIcon from '@mui/icons-material/Groups';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApiIcon from '@mui/icons-material/Api';
import KeyIcon from '@mui/icons-material/VpnKey';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SquareIcon from '@mui/icons-material/Square';

// Components
import Copyright from '../../Copyright';

// A ---------------------------------------------------------------------- M

const NavigationItem = ({ onClick, icon, text }) => (
    <ListItem
        onClick={onClick}
        sx={{
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                borderRadius: '0.375rem'
            },
            padding: '3px',
        }}
    >
        <ListItemIcon sx={{ minWidth: '35px' }}>{icon}</ListItemIcon>
        <ListItemText primary={text} />
    </ListItem>
);

const AMGSidebar = ({ drawerWidth, openQRCodeScannerDrawer, handleDrawerToggle, drawerOpen }) => {
    const { user, logout, isReseller, canAddUser, conservSostL1 } = UserAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/signin');
        } catch (e) {
            console.error('Error in logout:', e.message);
        }
    };

    let menuItems = [
        { text: conservSostL1 ? t('my_archives') : t('my_databoxes'), icon: <FolderIcon sx={{ color: 'black' }} />, route: '/' },
        { text: conservSostL1 ? t('my_documents') : t('my_certifications'), icon: <PlaylistAddCheckOutlinedIcon sx={{ color: 'black' }} />, route: '/my-certifications' },
        { text: t('my_groups'), icon: <GroupsIcon sx={{ color: 'black' }} />, route: '/my-groups' },
        { text: t('my_account'), icon: <AccountCircleIcon sx={{ color: 'black' }} />, route: '/my-account' },
        { text: t('scan_qr_code'), icon: <QrCodeScannerIcon sx={{ color: 'black' }} />, onClick: openQRCodeScannerDrawer },
        isReseller ? { text: t('reseller_panel'), icon: <ApiIcon sx={{ color: 'black' }} />, route: '/reseller-panel' } : null,
        !conservSostL1 && { text: t('generate_keypair'), icon: <KeyIcon sx={{ color: 'black' }} />, route: '/keypair-generator' },
        canAddUser ? { text: t('add_new_user'), icon: <PersonAddIcon sx={{ color: 'black' }} />, route: '/new-user' } : null,
        { text: t('logout'), icon: <LogoutIcon sx={{ color: 'black' }} />, onClick: handleLogout },
    ].filter(Boolean);

    const handleItemClick = (item) => {
        if (item.route && location.pathname === item.route) {
            if (isMobile) {
                handleDrawerToggle();
            }
        } else if (item.route) {
            if (isMobile) {
                handleDrawerToggle();
            }
            navigate(item.route);
        } else if (item.onClick) {
            item.onClick();
        }
    };

    const boxStyle = {
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            backgroundColor: 'rgba(241, 241, 241, 1)',
            color: 'black',
            borderWidth: '1px',
            borderRadius: '0.75rem',
            mt: '0.4%',
            ml: '0.4%'
        },
    };

    const innerBoxStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: '4.5%',
        pb: '5.5%',
        pr: '7%',
        pl: '4.5%',
        mb: '6%'
    };

    return (
        <Drawer
            anchor='left'
            variant='permanent'
            open={drawerOpen}
            onClose={handleDrawerToggle}
            sx={boxStyle}
        >
            <Box sx={innerBoxStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap='1%'>
                    <img src='/logo/AMG-logo.webp' style={{ height: '55px', width: '55px' }} />
                    <Typography fontSize='24px' fontWeight='900'>EASY<span style={{ color: 'red' }}>DAI</span></Typography>
                </Box>
                <IconButton onClick={handleDrawerToggle} edge="end">
                    <CancelOutlinedIcon sx={{ color: 'black' }} />
                </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mr: '6%' }}>
                <IconButton>
                    <SquareIcon sx={{ color: '#EF4444' }} fontSize='small' />
                </IconButton>
                <Typography align='center'><b>AMGDEMO</b></Typography>
            </Box>
            <Box pr='3.5%' pl='3.5%' pb='3.5%'>
                {user && <Typography align='center' variant='body2' gutterBottom>{user.email}</Typography>}
                <Divider sx={{ borderBottomWidth: 2, mt: '8%', mb: '5%' }} />
                <Typography align='center' gutterBottom>ARMILIS NIGHTLY</Typography>
                {user &&
                    <List>
                        {menuItems.map((item, index) => (
                            <React.Fragment key={item.text}>
                                <NavigationItem
                                    onClick={() => handleItemClick(item)}
                                    icon={item.icon}
                                    text={item.text}
                                />
                            </React.Fragment>
                        ))}
                    </List>
                }
            </Box>
            <Box sx={{ p: 2, mt: 'auto', mb: '5%' }}>
                <Copyright />
            </Box>
        </Drawer>
    );
};

export default AMGSidebar;