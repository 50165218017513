// React
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TaskIcon from '@mui/icons-material/Task';

// Functions
import downloadXMLFile from 'storage/downloadXMLFile';
import downloadFile from 'storage/downloadFile';
import convertTimestamp from 'utils/convertTimestamp';

// A ---------------------------------------------------------------------- M


const CDNPreservationProofDialog = ({ open, onClose, record }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <Grid container p='2%' spacing={3}>
                <Grid item container xs={12} alignItems='center' justifyContent='space-between'>
                    <Grid item>
                        <Typography variant='h5' fontWeight='bold'>Prova Di Conservazione</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose} sx={{ color: 'red' }} edge='end'>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                {/* Indice Di Conservazione Section */}
                <Grid item xs={12}>
                    <Paper elevation={5} sx={{ p: '3%' }}>
                        <Grid container>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={0.5}>
                                    <CheckCircleIcon sx={{ color: 'green' }} fontSize='large' />
                                </Grid>
                                <Grid item xs={8.5}>
                                    <Typography>
                                        L'indice di conservazione (IdC UniSincro) è stato <b>firmato digitalmente</b> e <b>marcato temporalmente</b> <br />in data <b>{convertTimestamp(record.data.timestamp) || 'N/A'}</b>.
                                    </Typography>
                                </Grid>
                                <Grid item container xs={3} justifyContent="flex-end" spacing={1}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            onClick={() => downloadXMLFile(`generatedXMLs/${record.id}_IdC.xml`, 'IdC', record.id)}
                                        >
                                            Download
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Files Section */}
                <Grid item xs={12}>
                    <Paper elevation={5} sx={{ p: '2%' }}>
                        <Grid container spacing={3}>
                            {/* File Section */}
                            <Grid item xs={12}>
                                <Paper elevation={4} sx={{ p: '1%' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={0.5}>
                                            <TaskIcon sx={{ color: 'green' }} fontSize='large' />
                                        </Grid>
                                        <Grid item container xs={8.5} direction="column">
                                            <Grid item>
                                                <Typography>Nome: {record.nomeFile}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Descrizione: File Dati</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Mime-Type: {record.formatoFile}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={3} justifyContent="flex-end" spacing={1}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => downloadFile(record.data.attachment, record.nomeFile)}
                                                >
                                                    Download
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {/* Gluer Section */}
                            <Grid item xs={12}>
                                <Paper elevation={4} sx={{ p: '1%' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={0.5}>
                                            <TaskIcon sx={{ color: 'green' }} fontSize='large' />
                                        </Grid>
                                        <Grid item xs={8.5} container direction="column">
                                            <Grid item>
                                                <Typography>Nome: gluer.xml</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Descrizione: File Parametri</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Mime-Type: text/xml</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={3} justifyContent="flex-end" spacing={1}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => downloadXMLFile(`generatedXMLs/${record.id}_gluer.xml`, 'gluer', record.id)}
                                                >
                                                    Download
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {/* Metadata Section */}
                            <Grid item xs={12}>
                                <Paper elevation={4} sx={{ p: '1%' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={0.5}>
                                            <TaskIcon sx={{ color: 'green' }} fontSize='large' />
                                        </Grid>
                                        <Grid item container xs={8.5} direction="column">
                                            <Grid item>
                                                <Typography>Nome: metadata.xml</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Descrizione: File Metadati</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Mime-Type: text/xml</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={3} justifyContent="flex-end" spacing={1}>
                                            {/*                                             <Grid item>
                                                <Button
                                                    variant="contained"
                                                >
                                                    Dettagli
                                                </Button>
                                            </Grid> */}
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => downloadXMLFile(`generatedXMLs/${record.id}_metadata.xml`, 'metadata', record.id)}
                                                >
                                                    Download
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Dialog>
    );
};

CDNPreservationProofDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired
};

export default CDNPreservationProofDialog;